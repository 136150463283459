import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import registrationReducer from "./features/registration/slice";
import tokensReducer from "./features/tokens/slice";
import flagsReducer from "./features/flags/slice";
import budgetRequestReducer from "./features/budget-request/slice";

const persistConfig = {
	key: "trigo",
	storage,
	whitelist: ["registration", "tokens", "flags", "budgetRequest"]
};

const rootReducer = combineReducers({
	registration: registrationReducer,
	tokens: tokensReducer,
	flags: flagsReducer,
	budgetRequest: budgetRequestReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk]
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
