const COUNTRY_BRAZIL = "55";

const MaskHelper = {
	cpfCnpj(cpfCnpj: string) {
		if (!cpfCnpj) {
			return cpfCnpj;
		}
		let value = cpfCnpj.replace(/\D/g, "").slice(0, 14);

		if (value.length <= 11) {
			value = value.replace(/(\d{3})(\d)/, "$1.$2");
			value = value.replace(/(\d{3})(\d)/, "$1.$2");
			value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
		} else {
			value = value.replace(/^(\d{2})(\d)/, "$1.$2");
			value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
			value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
			value = value.replace(/(\d{4})(\d)/, "$1-$2");
		}
		return value;
	},

	date(date: string) {
		if (!date) {
			return date;
		}
		return date.replace(/(\d{2})(\d{2})(\d{4}$)/, "$1/$2/$3").substring(0, 10);
	},

	monthYear(date: string) {
		console.log(date);

		if (!date) {
			return date;
		}
		return date.replace(/(\d{2})(\d{4})/, "$1/$2").substring(0, 7);
	},

	cep(cep: string) {
		if (!cep) {
			return cep;
		}
		return cep.replace(/(\d{5})(\d)/, "$1-$2");
	},

	phone(value: string, country = "55") {
		if (!value) {
			return undefined;
		}

		const numbers = value.toString().replace(/\D/g, "");
		if (country.toString() === COUNTRY_BRAZIL) {
			const matches = numbers.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
			if (matches) {
				const ddd = matches[1];
				const firstHalf = matches[2];
				const secondHalf = matches[3];

				if (numbers.length > 7) {
					return `(${ddd}) ${firstHalf}-${secondHalf}`;
				}

				if (numbers.length > 2) {
					return `(${ddd}) ${firstHalf}`;
				}

				return `(${ddd}`;
			}
		}
		return numbers;
	},
	protectedPhone(value: string, country = "55") {
		if (!value) {
			return undefined;
		}

		const numbers = value.toString().replace(/\D/g, "");
		if (country.toString() === COUNTRY_BRAZIL) {
			const matches = numbers.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
			if (matches) {
				const ddd = matches[1];
				const firstHalf = matches[2]
					.split("")
					.map((val, index) => {
						if (index === 0) return val;
						return "*";
					})
					.join("");
				const secondHalf = matches[3]
					.split("")
					.map((val, index, array) => {
						if (index >= array.length - 2) return val;
						return "*";
					})
					.join("");

				if (numbers.length > 7) {
					return `(${ddd}) ${firstHalf}${secondHalf}`;
				}

				if (numbers.length > 2) {
					return `(${ddd}) ${firstHalf}`;
				}

				return `(${ddd}`;
			}
		}
		return numbers;
	},
	protectedName(name = ""): string {
		const regex = /^([\w-]+)\s+(\b\w)/;
		const match = name.match(regex);
		return match ? match[0] : name;
	},

	fullDate(date = ""): string {
		const stringDate = date.replaceAll("/", "");

		if (stringDate.length >= 5) {
			const day = stringDate.substring(0, 2);
			const month = stringDate.substring(2, 4);
			const year = stringDate.substring(4, 10);
			return `${day}/${month}/${year}`;
		}
		if (stringDate.length >= 3) {
			const day = stringDate.substring(0, 2);
			const month = stringDate.substring(2, 4);
			return `${day}/${month}`;
		}
		if (stringDate.length <= 2) {
			return stringDate;
		}
		return stringDate;
	}
};

export default MaskHelper;
