/* eslint-disable import/no-duplicates */
import { compareAsc, format, isSameDay, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
import { Message } from "store/features/budget-request/types";

const locale = ptBR;

export const navigateExternalLink = (url: string) => {
	window.location.assign(url);
};

export const setCookies = (name: string, value: any, expire = 0) => {
	const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
	const expireInMillis = expire * ONE_DAY_IN_MILLIS;
	const expirationDate = new Date();

	expirationDate.setTime(expirationDate.getTime() + expireInMillis);
	Cookies.set(name, value, { expires: expirationDate });
};

export const getCookies = (name: string) => {
	return Cookies.get(name);
};

export const formatDate = (date: Date) => {
	return format(date, "dd/MM/yyyy '-' HH:mm:ss");
};

export const formatChatDate = (date: string) => {
	return format(parseISO(date), "eeee dd/MM", { locale });
};

export const formatMessageDate = (date: string) => {
	return format(parseISO(date), "kk:mm", { locale });
};

export const isTheSameDay = (firstDate: string, secondDate: string) => {
	const first = parseISO(firstDate);
	const second = parseISO(secondDate);
	return isSameDay(first, second);
};

export const sortArrayByTimestamp = (array: Message[]) =>
	array.sort((a, b) =>
		compareAsc(parseISO(a.created_at), parseISO(b.created_at))
	);

export const isValidDate = (date: Date) => {
	return date instanceof Date && !Number.isNaN(date.getTime());
};

export const getDateObject = (date: string) => {
	const dateParts = date.split("/");
	return new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
};

export const formatDateFromServer = (date = "") => {
	const dateParts = date.split("-");
	return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
};

export const goToSection = (section: string, offset: number) => {
	setTimeout(() => {
		const element = document.getElementById(section);
		if (element) {
			const y = element.getBoundingClientRect().top + window.scrollY - offset;
			window.scrollTo({ top: y, behavior: "smooth" });
		}
	}, 200);
};

export const dataLayer = ({ ...args }) => {
	return TagManager.dataLayer({
		dataLayer: { ...args }
	});
};

export const slugify = (text: string) => {
	return text
		.toString()
		.normalize("NFKD")
		.toLowerCase()
		.trim()
		.replace(/\s+/g, "-")
		.replace(/[^\w\\-]+/g, "")
		.replace(/\\-\\-+/g, "-");
};

export const validateUrl = (urlString = ""): boolean => {
	try {
		const url = new URL(urlString);
		return url.protocol === "http:" || url.protocol === "https:";
	} catch (err) {
		return false;
	}
};
