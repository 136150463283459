const FileHelper = {
	getBase64(file: File, callback: any) {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			callback(reader.result);
		};
		reader.onerror = (error) => {
			console.log("Error: ", error);
		};
	}
};

export default FileHelper;
