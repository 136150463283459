import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { BudgetRequestState } from "./types";

const initialState = {
	id: "",
	text: "",
	rating: 0,
	status: 0,
	created_at: "",
	service: "",
	requester: 0,
	messages: [],
	service_provider: {}
} as BudgetRequestState;

export const budgetRequestSlice = createSlice({
	name: "budgetRequest",
	initialState,
	reducers: {
		setInitialBudgetRequestInformation: (
			state,
			action: PayloadAction<BudgetRequestState>
		) => {
			state.id = action.payload.id;
			state.text = action.payload.text;
			state.rating = action.payload.rating;
			state.status = action.payload.status;
			state.created_at = action.payload.created_at;
			state.service = action.payload.service;
			state.requester = action.payload.requester;
			state.messages = action.payload.messages;
		}
	}
});

export const { setInitialBudgetRequestInformation } =
	budgetRequestSlice.actions;
export default budgetRequestSlice.reducer;
