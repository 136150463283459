// import { motion } from "framer-motion";

import { useEffect, useState } from "react";
import { ServiceType } from "store/features/registration/types";
import {
	Button,
	ButtonBack,
	Checkbox,
	Content,
	Header,
	Loading,
	Wrapper
} from "..";

import styles from "./styles.module.scss";

import { ServicesListProps } from "./types";

// TODO: poderia ir para o diretório da página ou ser adaptado para ficar mais genérico/reutilizável?
export default function ServicesList({
	title,
	services,
	isOpen,
	serviceProviderId,
	selectedServices,
	setSelectedServices,
	handleToggleServicesList,
	categoryId
}: ServicesListProps) {
	const [allSelected, setAllSelected] = useState<boolean>(false);

	useEffect(() => {
		const thisSelected = selectedServices.filter((s) => {
			return services.some((service) => service.id === s.service);
		});
		setAllSelected(thisSelected.length === services.length);
	}, [isOpen, services]);

	const handleCheckboxChange = (service: any) => {
		const newSelected = [...selectedServices];
		if (newSelected.some((s) => s.service === service.id)) {
			const index = newSelected.findIndex(
				(serv) => serv.service === service.id
			);
			newSelected.splice(index, 1);
			setSelectedServices(newSelected);
			if (allSelected) {
				setAllSelected(false);
			}
		} else {
			newSelected.push({
				service_provider: serviceProviderId,
				service: service.id,
				service_name: service.name,
				category: categoryId
			});
			setSelectedServices(newSelected);
		}
	};

	const selectAll = () => {
		const thisSelected = selectedServices.filter((s) => {
			return services.some((service) => service.id === s.service);
		});
		if (thisSelected.length === services.length) {
			// eslint-disable-next-line array-callback-return
			thisSelected.map((t) => {
				const index = selectedServices.findIndex(
					(serv) => t.service === serv.service
				);
				selectedServices.splice(index, 1);
			});
			setSelectedServices([...selectedServices]);
			setAllSelected(false);
		} else {
			const missing = services.filter((item) => {
				return !thisSelected.some((s) => s.service === item.id);
			});
			// eslint-disable-next-line array-callback-return
			missing.map((service) => {
				selectedServices.push({
					service_provider: serviceProviderId,
					service: service.id,
					service_name: service.name,
					category: categoryId
				});
			});

			setSelectedServices([...selectedServices]);
			setAllSelected(true);
		}
	};

	const handleAddSelectedServices = () => {
		handleToggleServicesList();
	};

	return (
		// <motion.div
		// 	initial={{ opacity: 0 }}
		// 	animate={{ y: isOpen ? "-100%" : "0%", opacity: 1 }}
		// 	transition={{ duration: 0.2, ease: "easeInOut" }}
		// 	className={styles.containerList}
		// >
		<>
			<Header
				title={title}
				trailing={<ButtonBack onBack={handleToggleServicesList} />}
			/>
			<Wrapper>
				{services.length ? (
					<Content>
						<Checkbox
							label={<span>Selecionar todos</span>}
							id="all"
							name="all"
							value="all"
							onChange={() => selectAll()}
							checked={allSelected}
						/>
						{services?.map((service: ServiceType, idx: number) => (
							<Checkbox
								key={`service-${idx}`}
								label={<span>{service.name}</span>}
								id={service.id.toString()}
								name={service.id.toString()}
								value={service.id.toString()}
								checked={selectedServices.some((s) => s.service === service.id)}
								onChange={() => handleCheckboxChange(service)}
							/>
						))}
						<Button type="button" onClick={handleAddSelectedServices}>
							Adicionar Serviços
						</Button>
					</Content>
				) : (
					<Loading />
				)}
			</Wrapper>
		</>
		// </motion.div>
	);
}
