import clsx from "clsx";
import styles from "./styles.module.scss";

interface LoadingProps {
	size?: "small" | "regular" | "large";
	className?: string;
}

export default function Loading({ size = "regular", className }: LoadingProps) {
	return (
		<div
			className={clsx([styles.loadingWrapper, className], {
				[styles.small]: size === "small",
				[styles.regular]: size === "regular",
				[styles.large]: size === "large"
			})}
		>
			<span className={styles.loading} />
		</div>
	);
}
