import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import {
	Badge,
	Button,
	ErrorState,
	ModalBudgetRequestRefuseConfirmation,
	Textarea
} from "components";

import { useEffect, useState } from "react";
import {
	getBudgetRequest,
	sendMessage,
	rejectBudgetRequest
} from "services/budget-request";
import { IcoError } from "assets/icons";
import { LogoHeader } from "components/Header";
import ChatMessage, {
	MessageBudgetAccepted,
	MessageReply
} from "components/ChatMessage";
import {
	formatChatDate,
	formatMessageDate,
	isTheSameDay,
	sortArrayByTimestamp
} from "utils/functions";
import {
	BudgetRequestState,
	Message
} from "store/features/budget-request/types";
import styles from "./styles.module.scss";

const MESSAGE_MAX_LENGTH = 600;
const MessageSchema = Yup.object().shape({
	message: Yup.string()
		.required("Campo obrigatório")
		.max(
			MESSAGE_MAX_LENGTH,
			`O texto deve conter até ${MESSAGE_MAX_LENGTH} caracteres.`
		)
});

export default function BudgetRequestPage() {
	const { budgetRequestId } = useParams();

	const [budgetRequestData, setBudgetRequestData] =
		useState<BudgetRequestState>();

	const [messageList, setMessageList] = useState<Message[]>([]);
	const [approvedTimestamp, setApprovedTimestamp] = useState("");

	const [showModal, setShowModal] = useState<boolean>(false);

	const [errorRetrievingData, setErrorRetrievingData] =
		useState<boolean>(false);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			message: ""
		},
		validateOnMount: true,
		validationSchema: MessageSchema,
		onSubmit: async (values) => {
			await sendMessage(values.message, budgetRequestId)
				.then(() => {
					navigate(`/budget-request/${budgetRequestId}/sent`);
				})
				.catch(() => {
					setErrorRetrievingData(true);
				});
		}
	});

	const setBudgetAcceptedTimestamp = (messages: Message[] = []) => {
		const accepted = messages.find(
			(message) => message.message_type === "budget_approved_message"
		);
		setApprovedTimestamp(formatMessageDate(accepted?.created_at || ""));
	};

	useEffect(() => {
		const fetchData = async () => {
			await getBudgetRequest(budgetRequestId)
				.then((resp) => {
					if (resp) {
						const data = resp as BudgetRequestState;
						setBudgetRequestData(data);
						setMessageList(data.messages);

						switch (data.status) {
							case 4: // refused
							case 7: // expired
								navigate(`/budget-request/${budgetRequestId}/expired`);
								break;
							case 3: // accepted
							case 6: // finalized
								setBudgetAcceptedTimestamp(data.messages);
								break;
							case 1:
							case 2:
							case 5:
							default:
						}
					}
				})
				.catch(() => {
					setErrorRetrievingData(true);
				});
		};
		fetchData();
	}, []);

	const handleToggleModal = () => {
		setShowModal(!showModal);
	};

	const handleRefuseBudgetRequest = async () => {
		await rejectBudgetRequest(formik.values.message, budgetRequestId)
			.then(() => {
				navigate(`/budget-request/${budgetRequestId}/rejected`);
			})
			.catch(() => {
				setErrorRetrievingData(true);
			});
	};

	return !errorRetrievingData ? (
		<>
			<LogoHeader title="Pedido de Orçamento" />
			<div className={styles.centerTag}>
				<Badge
					type="order"
					label={`ORÇAMENTO ${
						budgetRequestId ? budgetRequestId.slice(-6) : ""
					}`}
				/>
			</div>
			<div className={styles.centerTag}>
				<Badge
					type="timestamp"
					label={
						budgetRequestData?.created_at
							? formatChatDate(budgetRequestData?.created_at)
							: ""
					}
				/>
			</div>
			<ChatMessage
				service="Envie sua proposta"
				message="Não comunique dados de contato, apenas informações sobre o serviço"
				isUserMessage={false}
			/>

			{sortArrayByTimestamp(messageList).map(
				(item, index, array) =>
					item.message_source !== 3 && (
						<>
							{index > 0 &&
								!isTheSameDay(item.created_at, array[index - 1].created_at) && (
									<div className={styles.centerTag}>
										<Badge
											type="timestamp"
											label={formatChatDate(item.created_at)}
										/>
									</div>
								)}
							<ChatMessage
								message={item.message}
								timestamp={formatMessageDate(item.created_at)}
								isUserMessage={item.message_source === 2}
								profilePic={budgetRequestData?.service_provider.image}
								key={item.id}
							/>
						</>
					)
			)}
			{budgetRequestData?.status === 3 || budgetRequestData?.status === 6 ? (
				<>
					<MessageBudgetAccepted
						child={
							<div style={{ color: "#11A20F" }}>
								Orçamento aceito pelo cliente
							</div>
						}
						timestamp={approvedTimestamp}
					/>
					<MessageBudgetAccepted
						child={
							<p>
								O cliente entrará em contato diretamente via WhatsApp.
								<br />
								Por favor, aguarde sua mensagem.
							</p>
						}
						timestamp={approvedTimestamp}
					/>
				</>
			) : (
				<MessageReply
					profilePic={budgetRequestData?.service_provider.image}
					child={
						<form
							onSubmit={formik.handleSubmit}
							className={styles.messageTextarea}
						>
							<Textarea
								id="message"
								name="message"
								placeholder="Digite informações sobre seu serviço, como preço, disponibilidade e outros requisitos"
								label=""
								error={formik.touched.message && formik.errors.message}
								value={formik.values.message}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								errorMessage={formik.errors.message}
								maxLength={MESSAGE_MAX_LENGTH}
								showCount
								rows={5}
							/>
							<Button
								type="submit"
								disabled={
									(formik.dirty && !formik.isValid) ||
									(!formik.dirty && !formik.isValid)
								}
								className="mt-2"
							>
								Enviar Orçamento
							</Button>
							<Button
								type="button"
								variant="warning-text"
								onClick={handleToggleModal}
								aria-label="Go back"
							>
								Recusar Orçamento
							</Button>
						</form>
					}
				/>
			)}

			<ModalBudgetRequestRefuseConfirmation
				open={showModal}
				onClose={handleToggleModal}
				onAgree={handleRefuseBudgetRequest}
			/>
		</>
	) : (
		<ErrorState
			title="Houve um erro ao carregar seus dados"
			message="Entre em contato com nosso atendimento."
			icon={<IcoError />}
		/>
	);
}
