import { IconProps } from "./types";

export default function IcoExternalLink({
	color = "#773FAF",
	width = "20",
	height = "20"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.5312 4.87598L14.4375 4.90723C14.1875 4.90723 14 5.09473 14 5.34473V6.37598C14 6.62598 14.1875 6.84473 14.4375 6.84473L16.75 6.75098L16.8125 6.81348L8.09375 15.5322C8.03125 15.5947 7.96875 15.6885 7.96875 15.7822C7.96875 15.9072 8.03125 16.001 8.09375 16.0635L8.8125 16.7822C8.875 16.8447 8.96875 16.9072 9.09375 16.9072C9.1875 16.9072 9.28125 16.8447 9.34375 16.7822L18.0625 8.06348L18.125 8.12598L18.0312 10.4385C18.0312 10.6885 18.25 10.9072 18.5 10.9072V10.876H19.5312C19.7812 10.876 19.9688 10.6885 19.9688 10.4385L20 5.34473C20 5.09473 19.7812 4.87598 19.5312 4.87598ZM17.5 13.876H17C16.7188 13.876 16.5 14.126 16.5 14.376V19.1885C16.5 19.3135 16.4062 19.376 16.3125 19.376H5.6875C5.5625 19.376 5.5 19.3135 5.5 19.1885V8.56348C5.5 8.46973 5.5625 8.37598 5.6875 8.37598H10.5C10.75 8.37598 11 8.15723 11 7.87598V7.37598C11 7.12598 10.75 6.87598 10.5 6.87598H5.5C4.65625 6.87598 4 7.56348 4 8.37598V19.376C4 20.2197 4.65625 20.876 5.5 20.876H16.5C17.3125 20.876 18 20.2197 18 19.376V14.376C18 14.126 17.75 13.876 17.5 13.876Z"
				fill={color}
			/>
		</svg>
	);
}
