import { BadgeSuperPerfil } from "..";

import styles from "./styles.module.scss";

import { DescriptionPageProps } from "./types";

export default function DescriptionPage({
	title,
	description,
	isSuperPerfil = false
}: DescriptionPageProps) {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				{isSuperPerfil && <BadgeSuperPerfil />}
				<h2 className="heading_md text-neutral-700 font-normal">{title}</h2>
				<p className="body text-neutral-400 font-normal">{description}</p>
			</div>
		</div>
	);
}
