import { IcoDelete } from "assets/icons";
import { Content } from "..";

import { CertificationItemProps } from "./types";

export default function CertificationItem({
	title,
	institution,
	duration,
	onDelete
}: CertificationItemProps) {
	return (
		<Content>
			<div className="flex w-full justify-between items-center">
				<div className="flex flex-col gap-2">
					<h3 className="heading_sm">{title}</h3>
					<p className="body text-neutral-500">{institution}</p>
					<h3 className="heading_sm text-neutral-900">{duration}</h3>
				</div>
				<button type="button" aria-label="Remover" onClick={() => onDelete()}>
					<IcoDelete />
				</button>
			</div>
		</Content>
	);
}
