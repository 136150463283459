import { useNavigate } from "react-router-dom";

import { IcoAngleLeft } from "assets/icons";

import { ButtonBackProps } from "./types";

export default function ButtonBack({ onBack }: ButtonBackProps) {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(-1);
	};
	const handleBackButton = () => {
		if (onBack) {
			onBack();
		} else {
			handleClick();
		}
	};
	return (
		<button type="button" onClick={handleBackButton} aria-label="Go back">
			<IcoAngleLeft />
		</button>
	);
}
