import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import styles from "./styles.module.scss";

export default function TwoColumnsLayout() {
	return (
		<>
			<Helmet>
				<meta name="theme-color" content="#FAF3F0" />
			</Helmet>
			<div className={styles.container}>
				<div className={styles.image} />
				<div className={styles.wrapper}>
					<div className={styles.contentForm}>
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
