import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { FlagsState } from "./types";

const initialState = {
	isFirstAcess: true
} as FlagsState;

export const flagsSlice = createSlice({
	name: "flags",
	initialState,
	reducers: {
		setFirstAcess: (state, action: PayloadAction<boolean>) => {
			state.isFirstAcess = action.payload;
		}
	}
});

export const { setFirstAcess } = flagsSlice.actions;
export default flagsSlice.reducer;
