import WalkingLady from "assets/icons/walking-lady";
import { Wrapper } from "components";

import styles from "./styles.module.scss";

export default function SuccessPasswordResetPage() {
	return (
		<div className={styles.successContainer}>
			<h1 className="display_lg mb-3 text-center">
				Senha cadastrada com sucesso!
			</h1>
			<p className="text-lg font-normal text-center px-10 mb-6">
				Agora acesse o aplicativo para encontrar prestadores para a sua loja
			</p>
			<WalkingLady className="mt-7" />
		</div>
	);
}
