import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
	Button,
	Checkbox,
	Content,
	ErrorState,
	RegistrationHeader,
	TextInfo
} from "components";

import MaskHelper from "helpers/mask-helper";
import { useEffect, useState } from "react";
import { getServiceProvider } from "services/service-provider";
import { useDispatch } from "react-redux";
import {
	setAcceptedUserTerms,
	setInitialPersonalInformation
} from "store/features/registration/slice";
import { RegistrationState } from "store/features/registration/types";
import { setFirstAcess } from "store/features/flags/slice";
import { IcoError } from "assets/icons";
import { triggerAnalyticsEvent } from "services/firebase";

const SignupSchema = Yup.object().shape({
	accept: Yup.bool().oneOf([true], "Você deve concordar com os termos")
});

export default function MagicLinkPage() {
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const magicLinkId = searchParams.get("token");
	const [serviceProviderData, setServiceProviderData] = useState<any>([]);
	const [acceptedTermsChecked, setAcceptedTermsCheck] =
		useState<boolean>(false);
	const [errorRetrievingData, setErrorRetrievingData] =
		useState<boolean>(false);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			phone: "",
			password: "",
			accept: false
		},
		validationSchema: SignupSchema,
		onSubmit: (values) => {
			dispatch(setAcceptedUserTerms(values.accept));
			triggerAnalyticsEvent("cp-accept-terms-of-use");

			navigate("/cadastro/informacoes-pessoais");
		}
	});

	useEffect(() => {
		const fetchData = async () => {
			await getServiceProvider(magicLinkId)
				.then((resp) => {
					if (resp) {
						const personalData = resp as RegistrationState;
						setServiceProviderData(personalData);
						dispatch(setInitialPersonalInformation(personalData));
						dispatch(setFirstAcess(!personalData.accepted_user_terms));
						formik.setFieldValue("phone", personalData.phone);
						formik.setFieldValue("accept", personalData.accepted_user_terms);
						setAcceptedTermsCheck(personalData.accepted_user_terms);
						setIsLoaded(true);
					}
				})
				.catch((error) => {
					setErrorRetrievingData(true);
					setIsLoaded(false);
				});
		};
		fetchData();
	}, []);

	useEffect(() => {
		if (isLoaded) {
			triggerAnalyticsEvent("cp-open-magic-link", {
				is_first_access: acceptedTermsChecked === false
			});
		}
	}, [isLoaded]);

	const openTerms = () => {
		window.open(
			"https://grupotrigo.com.br/wp-content/uploads/2023/09/Grupo-Trigo-Politica-de-Privacidade.pdf",
			"_blank",
			"noreferrer"
		);
	};

	return !errorRetrievingData ? (
		<>
			<RegistrationHeader
				name={`Parabéns <span>${MaskHelper.protectedName(
					serviceProviderData.name
				)}.</span>`}
				description="Você recebeu um convite para fazer parte da nova plataforma de serviços do Grupo Trigo."
			/>
			<form onSubmit={formik.handleSubmit}>
				<Content>
					{formik?.values?.phone && (
						<TextInfo
							label="TELEFONE"
							value={String(MaskHelper.protectedPhone(formik?.values?.phone))}
						/>
					)}
					<Checkbox
						name="accept"
						id="accept"
						value="1"
						label={
							<span>
								Estou de acordo com a{" "}
								<button role="link" onClick={() => openTerms()}>
									Política de Privacidade
								</button>
								.
							</span>
						}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						checked={formik.values.accept}
					/>

					<Button type="submit" disabled={!formik.values.accept}>
						{acceptedTermsChecked ? "Continuar cadastro" : "Criar cadastro"}
					</Button>
				</Content>
			</form>
		</>
	) : (
		<ErrorState
			title="Houve um erro ao carregar seus dados"
			message="Entre em contato com nosso atendimento."
			icon={<IcoError />}
		/>
	);
}
