import { number } from "prop-types";

interface RequestOptions {
	method: string;
	headers?: { [key: string]: string };
	body?: string;
}

interface FetchSignature {
	url: string;
	token?: string;
	body?: string | object;
	success?: (data: any) => void;
	error?: (error: any) => void;
}

export interface ErrorResponse {
	message: string;
	code: string | number;
}

const getHeaders = (authToken = "") => {
	return {
		"Content-Type": "application/json",
		Authorization: authToken
	};
};

const handleResponse = (response: any) => {
	if (!response.ok) {
		return response.json().then((data: any) => {
			const error = {
				message: (data && data.message) || response.statusText,
				code: response.status,
				body: data
			};
			return Promise.reject(error);
		});
	}
	if (response.headers.get("Content-Type") === "application/json") {
		return response.json();
	}
	return response;
};

const get = (options: FetchSignature) => {
	const requestOptions: RequestOptions = {
		method: "GET",
		headers: getHeaders(options.token)
	};
	return fetch(options.url, requestOptions)
		.then(handleResponse)
		.then((data) => options?.success?.(data))
		.catch((error) => options?.error?.(error));
};

const post = (options: FetchSignature) => {
	const requestOptions: RequestOptions = {
		method: "POST",
		headers: getHeaders(options.token),
		body: JSON.stringify(options.body)
	};
	return fetch(options.url, requestOptions)
		.then(handleResponse)
		.then((data) => options?.success?.(data))
		.catch((error) => options?.error?.(error));
};

const put = (options: FetchSignature) => {
	const requestOptions: RequestOptions = {
		method: "PUT",
		headers: getHeaders(options.token),
		body: JSON.stringify(options.body)
	};
	return fetch(options.url, requestOptions)
		.then(handleResponse)
		.then((data) => options?.success?.(data))
		.catch((error) => options?.error?.(error));
};

const deleteReq = (options: FetchSignature) => {
	const requestOptions: RequestOptions = {
		method: "DELETE",
		headers: getHeaders(options.token)
	};
	return fetch(options.url, requestOptions)
		.then(handleResponse)
		.then((data) => options?.success?.(data))
		.catch((error) => options?.error?.(error));
};

export const Requester = {
	get,
	post,
	put,
	delete: deleteReq
};
