import { IconProps } from "./types";

export default function IcoEnter({
	color = "#773FAF",
	width = "24",
	height = "24"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.8125 5.21875L11.1875 5.8125C11.0312 5.96875 11.0312 6.21875 11.1875 6.34375L16.0312 11.1875H5.375C5.15625 11.1875 5 11.375 5 11.5625V12.4375C5 12.6562 5.15625 12.8125 5.375 12.8125H16.0312L11.1875 17.6875C11.0312 17.8125 11.0312 18.0625 11.1875 18.2188L11.8125 18.8125C11.9375 18.9688 12.1875 18.9688 12.3438 18.8125L18.875 12.2812C19.0312 12.125 19.0312 11.9062 18.875 11.75L12.3438 5.21875C12.1875 5.0625 11.9375 5.0625 11.8125 5.21875Z"
				fill={color}
			/>
		</svg>
	);
}
