import styles from "./styles.module.scss";

import { WrapperProps } from "./types";

export default function Wrapper({ children }: WrapperProps) {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>{children}</div>
		</div>
	);
}
