import { IconProps } from "./types";

export default function IcoClose({
	color = "#857e7a",
	width = "16",
	height = "16"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.97917 7.99967L11.2292 5.77051C11.3542 5.64551 11.3542 5.41634 11.2292 5.29134L10.7083 4.77051C10.5833 4.64551 10.3542 4.64551 10.2292 4.77051L8 7.02051L5.75 4.77051C5.625 4.64551 5.39583 4.64551 5.27083 4.77051L4.75 5.29134C4.625 5.41634 4.625 5.64551 4.75 5.77051L7 7.99967L4.75 10.2497C4.625 10.3747 4.625 10.6038 4.75 10.7288L5.27083 11.2497C5.39583 11.3747 5.625 11.3747 5.75 11.2497L8 8.99967L10.2292 11.2497C10.3542 11.3747 10.5833 11.3747 10.7083 11.2497L11.2292 10.7288C11.3542 10.6038 11.3542 10.3747 11.2292 10.2497L8.97917 7.99967Z"
				fill={color}
			/>
		</svg>
	);
}
