import LogoTrigo from "assets/icons/logo-trigo";
import styles from "./styles.module.scss";

import { HeaderProps } from "./types";

export default function Header({ title, trailing, leading }: HeaderProps) {
	return (
		<header className={styles.container}>
			<div className={styles.wrapper}>
				<div>{trailing}</div>
				<h1 className="heading_sm">{title}</h1>
				<div>{leading || " "}</div>
			</div>
		</header>
	);
}

export function LogoHeader({ title, trailing, leading }: HeaderProps) {
	return (
		<header className={styles.containerWithLogo}>
			<div className={styles.wrapperWithLogo}>
				<div>{trailing}</div>
				<LogoTrigo />
				<h1 className="heading_sm">{title}</h1>
				<div>{leading || " "}</div>
			</div>
		</header>
	);
}
