import { forwardRef, ForwardedRef } from "react";
import clsx from "clsx";

import { TextSmall } from "components/TextInfo";

import styles from "./styles.module.scss";
import { TextareaProps } from "./types";

const Input = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
	{ id, label, error, errorMessage, showCount, ...props }: TextareaProps,
	ref: ForwardedRef<HTMLTextAreaElement>
) {
	const getContentLength = () => `${props.value}`.length;
	return (
		<div className={styles.inputWrapper}>
			<div
				className={clsx(styles.container, {
					[styles.error]: error
				})}
			>
				<label
					className={clsx("overline_sm", "font-medium", "text-neutral-700")}
					htmlFor={id}
				>
					{label}
					<textarea {...props} className="body" id={id} ref={ref} />
					{showCount && (
						<span className={styles.count}>{`${getContentLength()}/${
							props.maxLength
						}`}</span>
					)}
				</label>
			</div>
			{error && errorMessage && <TextSmall label={errorMessage} error />}
		</div>
	);
});

export default Input;
