import { LogoTrigo } from "assets/icons";
import clsx from "clsx";

import styles from "./styles.module.scss";

export interface ErrorStateProps {
	title: string;
	message: string;
	icon: any;
}

export default function ErrorState({ title, message, icon }: ErrorStateProps) {
	return (
		<div className={styles.container}>
			<LogoTrigo />
			<div className={styles.icon}>{icon}</div>
			<div>
				<p className="text-xl">{title}</p>
				<p className={clsx(["text-base", "text-neutral-700"])}>{message}</p>
			</div>
		</div>
	);
}
