import clsx from "clsx";

import { IcoSuperPerfil } from "assets/icons";

import styles from "./styles.module.scss";

export default function BadgeSuperPerfil() {
	return (
		<span
			className={clsx([
				styles.superPerfil,
				"overline_sm",
				"font-semibold",
				"leading-none"
			])}
		>
			<IcoSuperPerfil /> SUPER PERFIL
		</span>
	);
}
