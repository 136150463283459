import clsx from "clsx";
import IcoSuperPerfil from "assets/icons/ico-super-perfil";
import { Badge } from "components";
import LogoTrigupAvatar from "assets/icons/logo-trigup-avatar";
import GenericProfilePic from "assets/images/generic-profile-pic.png";

import { ChatMessageProps, MessageReplyProps } from "./types";
import styles from "./styles.module.scss";

export default function ChatMessage({
	title,
	category,
	service,
	message,
	child,
	timestamp,
	isUserMessage,
	profilePic,
	isSuperProfile
}: ChatMessageProps) {
	return (
		<div>
			{isUserMessage ? (
				<div className={styles.userContainer}>
					<div>
						<div className={styles.userName}>
							{isSuperProfile && <IcoSuperPerfil color="#773FAF" />}
							<h3 className="heading_sm">(Você)</h3>
						</div>
						<p
							className="body text-neutral-500 "
							style={{ textAlign: "right" }}
						>
							{timestamp}
						</p>
					</div>
					<div className={styles.avatar}>
						<img src={profilePic || GenericProfilePic} alt="" />
					</div>
				</div>
			) : (
				<div className={styles.container}>
					<div className={styles.avatar}>
						<LogoTrigupAvatar />
					</div>
					<div>
						<div className={styles.userName}>
							<h3 className="heading_sm">TrigUP</h3>
							{isSuperProfile && <IcoSuperPerfil color="#773FAF" />}
						</div>
						<p className="body text-neutral-500"> {timestamp}</p>
					</div>
				</div>
			)}

			<div
				className={clsx({
					[styles.userMessageContainer]: isUserMessage,
					[styles.messageContainer]: !isUserMessage
				})}
			>
				{typeof title === "string" && (
					<div className="heading_md">{title || ""}</div>
				)}
				{typeof category === "string" && (
					<Badge type="category" label={category || ""} />
				)}
				<div className="heading_sm">{service}</div>
				<div className={clsx(["body text-neutral-500", styles.messageContent])}>
					{message || child}
				</div>
			</div>
		</div>
	);
}
export function MessageBudgetAccepted({ child, timestamp }: MessageReplyProps) {
	return (
		<div>
			<div className={styles.container}>
				<div className={styles.avatar}>
					<LogoTrigupAvatar />
				</div>
				<div>
					<div className={styles.userName}>
						<h3 className="heading_sm">TrigUP</h3>
						{/* <IcoSuperPerfil color="#773FAF" /> */}
					</div>
					<p className="body text-neutral-500">{timestamp}</p>
				</div>
			</div>
			<div className={styles.messageContainer}>{child}</div>
		</div>
	);
}

export function MessageReply({ child, profilePic }: MessageReplyProps) {
	return (
		<div>
			<div className={styles.userContainer}>
				<div>
					<div className={styles.userName}>
						<IcoSuperPerfil color="#773FAF" />
						<h3 className="heading_sm">(Você)</h3>
					</div>
				</div>
				<div className={styles.avatar}>
					<img src={profilePic || GenericProfilePic} alt="" />
				</div>
			</div>
			<div className={styles.userMessageContainerReply}>{child}</div>
		</div>
	);
}
