import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { TokensState } from "./types";

const initialState = {
	access: "",
	refresh: "",
	access_token: ""
} as TokensState;

export const tokensSlice = createSlice({
	name: "tokens",
	initialState,
	reducers: {
		setTokens: (state, action: PayloadAction<TokensState>) => {
			state.access = action.payload.access;
			state.refresh = action.payload.refresh;
			state.access_token = action.payload.access_token;
		},
		setLogout: () => initialState,
		setRefreshToken: (state, action: PayloadAction<TokensState>) => {
			state.refresh = action.payload.refresh;
		}
	}
});

export const { setTokens, setLogout, setRefreshToken } = tokensSlice.actions;
export default tokensSlice.reducer;
