export default function OrderSent(props: any) {
	return (
		<svg
			width="390"
			height="420"
			viewBox="0 0 390 420"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_3356_18892)">
				<mask
					id="mask0_3356_18892"
					maskUnits="userSpaceOnUse"
					x="-49"
					y="-20"
					width="428"
					height="480"
				>
					<path d="M379 -19.502H-49V459.497H379V-19.502Z" fill="white" />
				</mask>
				<g mask="url(#mask0_3356_18892)">
					<path
						d="M161.964 261.624C161.964 261.624 119.286 243.066 105.721 254.648C92.1554 266.23 92.0447 286.619 94.1519 295.569C96.259 304.519 134.037 337.978 165.133 331.924C196.23 325.87 206.334 266.889 161.964 261.624Z"
						fill="#FFAD7D"
					/>
					<path
						d="M138.087 337.667C138.087 337.667 291.714 340.748 302.593 346.032C313.473 351.317 355.637 822.93 352.122 830.769C348.606 838.609 327.468 841.489 305.931 841.495C276.756 841.502 280.757 825.407 276.756 802.779C247.873 639.409 219.622 461.862 217.307 439.653C215.154 461.571 209.277 576.368 209.614 583.461C211.498 623.033 224.027 825.825 220.512 833.665C216.996 841.504 196.543 845.332 175.008 844.993C153.474 844.655 152.977 833.997 155.155 811.123C156.069 801.519 140.11 620.983 135.805 582.287C126.839 501.699 138.087 337.667 138.087 337.667Z"
						fill="#A984CE"
					/>
					<path
						d="M138.087 337.667C138.087 337.667 170.338 338.314 206.332 339.523L287.933 358.72C287.933 358.72 286.563 365.004 229.656 368.316C192.89 370.457 157.167 367.024 136.483 364.325C137.391 347.826 138.087 337.667 138.087 337.667Z"
						fill="#2E2E47"
					/>
					<path
						d="M299.729 351.925C299.729 351.925 315.371 481.695 315.841 498.109C316.31 514.523 346.665 825.926 346.665 825.926L335.872 829.426L292.131 351.925H299.729Z"
						fill="white"
					/>
					<path
						d="M175.352 141.352C175.352 141.352 209.066 94.4671 246.636 102.693C301.965 114.807 304.169 177.046 307.858 193.39C311.546 209.733 320.705 303.218 312.573 345.388C306.787 375.395 138.975 355.795 135.19 342.815C129.657 323.841 143.903 286.348 146.669 271.619C149.436 256.891 154.42 175.646 175.352 141.352Z"
						fill="#FFAD7D"
					/>
					<path
						d="M236.834 288.097C256.927 300.007 299.367 299.095 315.839 298.229C315.855 315.082 315.067 330.9 313.001 343.014C292.847 343.677 254.985 343.135 234.666 331.398C206.111 314.902 186.42 263.809 186.42 263.809C186.42 263.809 211.595 273.137 236.834 288.097Z"
						fill="#2E2E47"
					/>
					<path
						d="M275.395 105.884C276.76 111.566 274.128 117.457 269.006 120.276C260.924 124.724 249 131.47 245.169 134.603C239.185 139.496 218.349 146.834 218.349 146.834L200.883 136.609C200.883 136.609 199.804 121.517 199.425 113.675C199.143 107.831 208.809 94.921 216.382 91.9311C223.955 88.9413 259.052 92.9174 267.483 94.2764C271.761 94.9662 274.115 100.55 275.395 105.884Z"
						fill="#FFAD7D"
					/>
					<path
						d="M174.56 174.423L144.494 176.036C142.288 176.154 140.596 178.039 140.715 180.244L143.207 226.655C143.325 228.861 145.21 230.553 147.416 230.435L177.482 228.822C179.688 228.704 181.38 226.82 181.262 224.614L178.769 178.203C178.651 175.998 176.766 174.305 174.56 174.423Z"
						fill="#5E328B"
					/>
					<path
						d="M251.056 71.6956C251.056 71.6956 251.376 91.9349 253.554 105.417C254.22 109.537 246.28 114.158 238.506 118.84C233.333 121.956 228.949 126.218 224.615 126.293C213.766 126.483 225.606 105.605 226.846 100.589C228.087 95.5721 231.576 77.9771 232.357 75.8259C233.137 73.6745 248.048 68.6792 251.056 71.6956Z"
						fill="#DEBDFF"
					/>
					<path
						d="M160.091 275.879C159.905 275.879 159.717 275.829 159.548 275.724C159.067 275.424 158.92 274.791 159.22 274.31C165.107 264.869 166.036 252.626 165.779 244.018C165.498 234.621 163.78 227.387 163.763 227.315C163.63 226.764 163.969 226.21 164.521 226.077C165.071 225.944 165.627 226.284 165.76 226.835C165.832 227.136 167.539 234.312 167.831 243.912C168.101 252.817 167.13 265.507 160.963 275.395C160.768 275.708 160.433 275.879 160.091 275.879Z"
						fill="#2E2E47"
					/>
					<path
						d="M226.846 100.588C227.321 98.6675 228.126 94.904 228.981 90.8699L245.952 86.7712C245.952 86.7712 239.892 101.609 225.82 103.461C226.304 102.268 226.676 101.277 226.846 100.588Z"
						fill="#2E2E47"
					/>
					<path
						d="M223.56 433.608C223.527 433.608 223.494 433.601 223.462 433.586C223.39 433.552 216.118 430.107 207.173 426.412C198.923 423.004 187.523 418.682 180.062 417.439C179.937 417.418 179.853 417.301 179.874 417.176C179.895 417.052 180.012 416.967 180.137 416.989C187.641 418.239 199.077 422.574 207.349 425.991C216.305 429.691 223.585 433.14 223.658 433.174C223.772 433.228 223.82 433.365 223.766 433.478C223.727 433.56 223.645 433.608 223.56 433.608Z"
						fill="white"
					/>
					<path
						d="M259.218 48.9261C259.218 48.9261 259.763 62.4589 257.328 68.1264C254.893 73.7939 247.851 79.4737 247.356 76.7573C246.862 74.0409 244.461 46.7644 247.356 46.3826C250.252 46.001 258.229 45.0489 259.218 48.9261Z"
						fill="#F55A00"
					/>
					<path
						d="M198.85 226.098C198.85 226.098 201.742 215.863 197.457 209.445C193.173 203.026 186.914 196.428 185.401 192.526C183.888 188.624 188.409 178.997 181.918 178.988C175.426 178.979 179.284 197.478 179.309 199.898C179.334 202.318 168.718 198.386 164.426 195.779C158.882 192.41 149.471 191.936 148.927 193.748C148.543 195.027 147.965 196.375 150.775 197.793C153.586 199.212 157.284 199.315 162.548 202.852C165.968 205.15 170.488 208.888 166.891 208.115C163.294 207.342 156.898 204.685 155.378 204.317C153.859 203.95 150.873 202.024 149.838 203.308C148.802 204.591 147.638 207.075 148.973 209.997C148.973 209.997 147.16 212.012 148.624 216.451C149.636 219.521 151.124 219.101 151.124 219.101C151.124 219.101 150.985 223.212 152.261 224.768C153.206 225.921 163.937 230.145 173.22 232.122C182.503 234.098 191.835 240.915 198.85 226.098Z"
						fill="#DEBDFF"
					/>
					<path
						d="M287.934 130.28L275.753 252.267C275.753 252.267 235.707 214.019 212.208 210.397C188.709 206.775 179.484 233.445 176.848 245.628C174.212 257.811 244.833 334.277 287.934 332.883C331.034 331.489 347.159 272.78 331.044 220.604C307.981 145.932 287.934 130.28 287.934 130.28Z"
						fill="#FFAD7D"
					/>
					<path
						d="M202.174 25.7656C197.834 16.6606 206.089 10.5002 218.304 10.5002C231.803 10.5002 249.045 21.9234 249.045 21.9234C249.045 21.9234 252.845 19.937 262.033 24.4395C271.222 28.942 268.46 55.6915 259.27 65.3597C250.079 75.028 254.983 82.3115 245.952 77.1468C237.002 72.0283 209.198 40.4983 202.174 25.7656Z"
						fill="#F55A00"
					/>
					<path
						d="M201.309 38.3033C201.309 38.3033 198.26 32.7177 198.768 31.3637C199.276 30.0098 205.373 24.5934 215.366 24.7627C225.359 24.9319 242.39 30.1473 246.386 32.5911C252.227 36.1631 257.257 47.6609 257.257 47.6609C257.257 47.6609 258.386 49.9824 251.611 48.4592C244.836 46.936 219.431 35.5956 214.858 35.5956C210.285 35.5956 201.309 38.3033 201.309 38.3033Z"
						fill="#F55A00"
					/>
					<path
						d="M200.547 35.1716C200.547 35.1716 200.547 52.69 200.547 58.0217C200.547 63.3534 199.53 68.6852 200.547 76.5556C201.563 84.4262 206.898 97.8822 221.633 97.6283C236.368 97.3745 250.341 85.1878 252.628 76.8094C254.914 68.4311 253.898 64.8767 253.898 64.8767C253.898 64.8767 258.471 64.8767 260.249 58.7833C262.028 52.69 263.817 44.677 258.482 44.4232C253.147 44.1693 252.374 47.1044 251.103 46.8506C249.833 46.5967 243.227 32.8866 236.368 31.6172C229.509 30.3479 203.849 27.3012 200.547 35.1716Z"
						fill="#DEBDFF"
					/>
					<path
						d="M210.479 76.1638C209.609 76.1638 208.768 76.0057 207.959 75.6891C206.537 75.1332 205.619 74.2911 205.228 73.1862C204.669 71.6035 205.159 69.5545 206.77 66.7377C208.731 63.3081 210.772 53.3125 208.341 50.6993C206.005 48.1875 200.752 50.7465 200.699 50.7727C200.528 50.8564 200.323 50.7873 200.24 50.6183C200.156 50.449 200.225 50.2438 200.394 50.1598C200.452 50.1313 201.831 49.4529 203.549 49.1067C205.888 48.635 207.718 49.0248 208.842 50.2333C211.503 53.0933 209.458 63.4153 207.364 67.0772C205.88 69.6722 205.393 71.5962 205.874 72.9583C206.2 73.8803 206.963 74.5648 208.208 75.0518C212.082 76.5668 215.798 73.6796 217.019 72.7307C217.168 72.6146 217.384 72.6413 217.499 72.7907C217.616 72.9399 217.589 73.1547 217.439 73.2708C214.962 75.1969 212.631 76.1638 210.479 76.1638Z"
						fill="#2E2E47"
					/>
					<path
						d="M216.043 49.2668C215.994 49.2668 215.944 49.2561 215.896 49.2333C215.726 49.1521 215.653 48.9488 215.734 48.7784C215.786 48.6701 217.037 46.1159 220.722 45.4143C224.289 44.7352 229.742 45.3828 229.973 45.4106C230.161 45.4334 230.295 45.6038 230.272 45.7913C230.249 45.979 230.078 46.1118 229.891 46.0899C229.836 46.0833 224.313 45.4273 220.85 46.0862C217.521 46.7199 216.399 48.9764 216.353 49.0722C216.294 49.1952 216.171 49.2668 216.043 49.2668Z"
						fill="#2E2E47"
					/>
					<path
						d="M224.468 57.3098C222.184 57.3098 220.524 56.5498 220.406 56.4946L220.28 56.4353L219.111 53.3186C219.044 53.1416 219.134 52.9443 219.311 52.8782C219.488 52.8116 219.685 52.9015 219.752 53.0782L220.821 55.9276C221.588 56.2387 225.783 57.7291 229.735 54.9498C229.89 54.8409 230.103 54.8779 230.212 55.0325C230.321 55.1872 230.284 55.4005 230.129 55.5093C228.149 56.9018 226.142 57.3098 224.468 57.3098Z"
						fill="#2E2E47"
					/>
					<path
						d="M200.095 59.2963C199.906 59.2963 199.753 59.1432 199.753 58.9541C199.753 58.7653 199.906 58.612 200.095 58.612C200.137 58.612 204.346 58.6033 205.932 57.8937C206.724 57.5393 206.472 55.7585 206.321 55.1625C206.275 54.9794 206.386 54.7932 206.569 54.7469C206.752 54.7004 206.938 54.8113 206.985 54.9942C207.059 55.2862 207.669 57.8661 206.212 58.5183C204.493 59.2876 200.274 59.2963 200.095 59.2963Z"
						fill="#2E2E47"
					/>
					<path
						d="M212.995 81.6386C212.817 81.6386 212.667 81.5018 212.654 81.322C212.64 81.1336 212.781 80.9696 212.969 80.9555C213.014 80.952 217.499 80.5796 221.763 77.3016C221.913 77.1867 222.128 77.2143 222.243 77.3643C222.359 77.5142 222.33 77.7288 222.18 77.8442C217.747 81.2527 213.211 81.6238 213.02 81.6379C213.012 81.6382 213.003 81.6386 212.995 81.6386Z"
						fill="#2E2E47"
					/>
					<path
						d="M221.718 56.5862C221.718 56.5862 221.78 59.6301 224.681 59.4242C227.583 59.218 228.501 56.0627 228.501 56.0627C228.501 56.0627 223.853 57.0451 221.718 56.5862ZM201.177 58.8108C201.177 58.8108 201.225 61.8548 203.493 61.6488C205.76 61.4426 206.478 58.2874 206.478 58.2874C206.478 58.2874 202.845 59.27 201.177 58.8108Z"
						fill="#2E2E47"
					/>
					<path
						d="M227.858 68.1257C231.248 68.1257 233.997 66.6781 233.997 64.8924C233.997 63.1067 231.248 61.6592 227.858 61.6592C224.467 61.6592 221.719 63.1067 221.719 64.8924C221.719 66.6781 224.467 68.1257 227.858 68.1257Z"
						fill="#FFB082"
					/>
					<path
						d="M201.837 69.4802C203.858 69.4802 205.496 68.0326 205.496 66.2469C205.496 64.4612 203.858 63.0137 201.837 63.0137C199.816 63.0137 198.178 64.4612 198.178 66.2469C198.178 68.0326 199.816 69.4802 201.837 69.4802Z"
						fill="#FFB082"
					/>
					<path
						d="M247.669 36.8499C247.669 36.8499 237.023 33.5847 226.477 35.2952C215.93 37.0055 199.465 45.2461 191.517 41.048C183.569 36.8499 181.355 20.3691 189.774 16.1711C198.193 11.9732 217.952 13.6835 221.842 16.9484C225.731 20.2136 224.176 24.567 224.176 24.567C224.176 24.567 242.223 22.5458 247.669 36.8499Z"
						fill="#F55A00"
					/>
					<path
						d="M133.927 333.13C133.825 333.13 133.732 333.061 133.706 332.957C132.224 326.993 134.119 314.308 139.338 295.254C143.221 281.082 147.594 268.494 147.637 268.369C147.679 268.25 147.808 268.187 147.928 268.228C148.047 268.27 148.11 268.399 148.068 268.518C148.024 268.644 143.656 281.219 139.778 295.377C136.204 308.423 132.326 325.514 134.149 332.846C134.179 332.969 134.105 333.092 133.982 333.123C133.964 333.128 133.945 333.13 133.927 333.13Z"
						fill="#2E2E47"
					/>
					<path
						d="M302.654 308.244C301.88 308.244 299.728 307.11 290.318 289.466C285.174 279.822 280.437 270.027 280.105 269.033C280.046 268.854 280.143 268.66 280.322 268.6C280.501 268.54 280.695 268.637 280.755 268.817C281.072 269.768 285.89 279.71 290.922 289.145C300.395 306.906 302.408 307.601 302.724 307.552C302.758 307.547 302.891 307.408 302.916 306.816C303.259 298.59 275.737 252.905 275.459 252.444C275.362 252.282 275.414 252.072 275.576 251.974C275.737 251.876 275.948 251.928 276.046 252.09C276.116 252.206 283.093 263.775 289.937 276.225C299.151 292.985 303.748 303.287 303.6 306.844C303.564 307.695 303.302 308.162 302.819 308.23C302.772 308.236 302.717 308.244 302.654 308.244Z"
						fill="#2E2E47"
					/>
					<path
						d="M311.336 289.275C311.173 289.275 311.029 289.159 310.999 288.994C308.591 275.589 281.191 252.76 280.914 252.531C280.769 252.41 280.748 252.194 280.869 252.049C280.99 251.903 281.206 251.883 281.351 252.004C281.421 252.061 288.384 257.846 295.558 265.23C305.219 275.175 310.641 283.129 311.673 288.873C311.706 289.059 311.583 289.237 311.396 289.27C311.376 289.274 311.356 289.275 311.336 289.275Z"
						fill="#2E2E47"
					/>
					<path
						d="M223.587 65.2349C218.658 65.2349 214.648 61.8459 214.648 57.68C214.648 53.5143 218.658 50.1252 223.587 50.1252C228.516 50.1252 232.526 53.5143 232.526 57.68C232.526 61.8459 228.516 65.2349 223.587 65.2349ZM223.587 50.8098C219.035 50.8098 215.333 53.8918 215.333 57.6802C215.333 61.4686 219.036 64.5509 223.587 64.5509C228.138 64.5509 231.842 61.4686 231.842 57.6802C231.842 53.8918 228.139 50.8098 223.587 50.8098Z"
						fill="#2E2E47"
					/>
					<path
						d="M202.08 65.2349C198.869 65.2349 196.255 61.8459 196.255 57.68C196.255 53.5143 198.869 50.1252 202.08 50.1252C205.292 50.1252 207.906 53.5143 207.906 57.68C207.905 61.8459 205.292 65.2349 202.08 65.2349ZM202.08 50.8098C199.246 50.8098 196.94 53.8918 196.94 57.6802C196.94 61.4686 199.246 64.5509 202.08 64.5509C204.915 64.5509 207.221 61.4686 207.221 57.6802C207.221 53.8918 204.915 50.8098 202.08 50.8098Z"
						fill="#2E2E47"
					/>
					<path
						d="M207.563 58.0235C207.438 58.0235 207.318 57.9546 207.258 57.8355C207.172 57.667 207.24 57.461 207.409 57.3759C211.895 55.1112 215.057 57.3084 215.19 57.4031C215.343 57.513 215.379 57.7267 215.269 57.8804C215.159 58.0342 214.945 58.0695 214.792 57.96C214.67 57.8745 211.818 55.9161 207.718 57.9867C207.668 58.0116 207.615 58.0235 207.563 58.0235Z"
						fill="#2E2E47"
					/>
					<path
						d="M230.76 54.0465C230.617 54.0465 230.483 53.9564 230.435 53.8136C230.375 53.6346 230.471 53.4405 230.65 53.3803L250.994 46.5272C251.175 46.4665 251.368 46.5632 251.428 46.742C251.489 46.9211 251.392 47.1152 251.213 47.1754L230.869 54.0285C230.833 54.0408 230.796 54.0465 230.76 54.0465Z"
						fill="#2E2E47"
					/>
					<path
						d="M221.26 144.42C219.227 144.42 217.415 144.155 215.914 143.808C211.051 142.683 207.261 140.251 205.335 138.35C201.421 134.485 199.285 124.38 199.196 123.952C199.17 123.828 199.249 123.708 199.373 123.682C199.496 123.656 199.617 123.735 199.643 123.859C199.664 123.962 201.84 134.258 205.657 138.025C207.538 139.883 211.247 142.26 216.017 143.363C219.87 144.254 225.814 144.595 232.249 141.159C243.912 134.933 268.862 118.809 269.113 118.647C269.219 118.578 269.36 118.609 269.428 118.714C269.497 118.82 269.466 118.961 269.361 119.03C269.11 119.192 244.143 135.326 232.464 141.562C228.41 143.726 224.547 144.42 221.26 144.42Z"
						fill="#2E2E47"
					/>
					<path
						d="M227.75 137.662L223.887 188.728L220.075 187.148L224.883 138.539L227.75 137.662Z"
						fill="white"
					/>
					<path
						d="M212.995 137.661L204.571 184.352L199.419 182.283L209.488 136.257L212.995 137.661Z"
						fill="white"
					/>
					<path
						d="M73.6316 242.581C73.6316 242.581 76.667 247.509 78.099 251.496C78.7244 253.237 80.7996 253.956 82.3763 252.987L91.0142 247.678C91.0142 247.678 90.5502 239.912 85.4291 238.604C80.308 237.295 81.3108 234.73 73.6316 242.581Z"
						fill="#2E2E47"
					/>
					<path
						d="M160.577 311.807C169.225 304.118 174.297 295.178 170.528 290.809L174.176 286.689C171.952 282.334 169.619 278.498 167.463 275.914C159.762 266.683 132.375 253.907 127.133 244.848C123.822 239.126 112.225 227.907 104.131 220.418C100.977 217.5 100.598 212.663 103.232 209.269C107.796 203.388 111.27 196.238 109.687 191.13C108.751 188.112 107.43 184.971 105.567 182.04C103.789 179.055 101.58 176.461 99.3061 174.265C93.0801 168.252 76.1092 171.638 68.495 175.757L29.4912 196.858C28.4351 197.43 27.4886 198.161 26.6794 199.018C29.8532 201.64 36.126 207.689 44.1604 219.786C51.6614 231.079 54.2145 237.612 55.9252 244.353C56.657 244.05 57.3599 243.669 58.0207 243.21L60.4595 241.519C62.9063 239.822 66.1891 240.076 68.385 242.087C69.3393 242.961 70.345 243.571 71.2751 243.541C74.4113 243.44 79.4268 235.998 83.8191 239.852C88.2112 243.706 88.0026 253.76 93.9773 253.62C99.952 253.481 100.419 251.511 103.661 253.22C106.903 254.928 126.165 282.12 128.259 286.889C130.353 291.659 106.835 313.35 109.35 322.907C110.101 325.758 111.263 329.839 112.698 334.122C119.429 330.654 139.861 320.121 146.12 316.812C146.12 316.811 151.929 319.495 160.577 311.807Z"
						fill="#F55A00"
					/>
					<path
						d="M170.528 290.809C174.297 295.178 169.225 304.119 160.577 311.807C151.929 319.495 146.12 316.811 146.12 316.811C139.861 320.121 119.429 330.654 112.698 334.122C116.071 344.196 120.949 355.393 125.511 354.36C132.012 352.888 183.657 326.021 184.382 318.172C184.904 312.522 179.896 297.887 174.177 286.689L170.528 290.809Z"
						fill="#2E2E47"
					/>
					<path
						d="M-9.64084 232.476C-17.7786 237.478 -20.3414 248.225 -15.3358 256.357C-10.3304 264.489 0.423101 267.051 8.5609 262.048L27.0361 250.692L8.83456 221.119L-9.64084 232.476Z"
						fill="#2E2E47"
					/>
					<path
						d="M6.75982 219.848C-3.66726 226.257 -0.413875 234.928 5.12468 243.926C10.7915 253.133 16.9395 259.737 27.3663 253.328L51.8167 240.04L29.5251 203.822L6.75982 219.848Z"
						fill="#2E2E47"
					/>
					<path
						d="M44.1603 219.785C36.1262 207.689 29.8531 201.64 26.6794 199.017C25.5074 200.259 24.6241 201.763 24.116 203.425L22.4792 208.781L33.3441 226.433L44.2089 244.086L49.7304 245.041C51.8348 245.406 53.9819 245.157 55.9252 244.352C54.2145 237.611 51.6613 231.078 44.1603 219.785Z"
						fill="#F55A00"
					/>
					<path
						d="M84.3279 180.273C84.237 180.329 84.1357 180.372 84.0261 180.398C78.1313 181.82 70.0259 185.942 69.9447 185.984C69.4325 186.245 68.805 186.043 68.5432 185.531C68.2814 185.019 68.4848 184.393 68.9965 184.13C69.3371 183.956 77.4052 179.854 83.5375 178.375C84.0967 178.24 84.6593 178.584 84.7942 179.143C84.903 179.592 84.7017 180.043 84.3279 180.273Z"
						fill="#2E2E47"
					/>
					<path
						d="M86.754 184.216C86.6632 184.271 86.5618 184.314 86.4523 184.341C80.562 185.761 72.452 189.884 72.3708 189.926C71.8586 190.188 71.2311 189.984 70.9693 189.473C70.7076 188.961 70.9091 188.334 71.4226 188.072C71.7632 187.898 79.8314 183.796 85.9636 182.317C86.5228 182.182 87.0854 182.526 87.2203 183.084C87.3289 183.534 87.1276 183.986 86.754 184.216Z"
						fill="#2E2E47"
					/>
					<path
						d="M89.1797 188.156C89.0887 188.212 88.9876 188.254 88.878 188.281C82.9877 189.702 74.8778 193.825 74.7965 193.866C74.2843 194.128 73.6569 193.925 73.3951 193.413C73.1333 192.901 73.3351 192.275 73.8484 192.013C74.1889 191.839 82.2571 187.736 88.3893 186.257C88.9485 186.122 89.5111 186.466 89.646 187.025C89.7547 187.474 89.5536 187.926 89.1797 188.156Z"
						fill="#2E2E47"
					/>
					<path
						d="M141.066 281.318C145.578 275.093 125.741 246.603 115.948 244.715C107.802 243.145 103.944 250.379 102.877 252.856C103.121 252.953 103.381 253.072 103.661 253.22C106.903 254.929 126.165 282.12 128.259 286.89C128.651 287.783 128.145 289.268 127.081 291.156C132.513 288.297 138.723 284.548 141.066 281.318Z"
						fill="#2E2E47"
					/>
					<path
						d="M95.1027 188.898C94.8042 188.79 94.4723 188.823 94.2018 188.99L60.5396 209.682C60.2173 209.88 60.0279 210.237 60.0447 210.615C60.0616 210.993 60.2823 211.332 60.6211 211.5C60.6681 211.524 62.5522 215.3 64.2008 221.175C65.8065 226.898 66.8637 232.5 61.8294 240.569L65.796 237.818C67.4348 234.643 69.2163 228.156 67.0746 220.703C65.7545 216.109 64.7039 213.031 63.0457 210.585L94.1405 191.472C95.1497 195.041 97.6314 205.394 94.8414 212.621C93.9378 214.961 93.4304 217.03 93.1814 218.824L94.4579 217.938C94.8635 217.657 95.2554 217.362 95.6348 217.055C95.8984 215.933 96.2697 214.704 96.7849 213.37C97.6438 211.145 98.119 208.498 98.197 205.501C98.2586 203.138 98.0749 200.547 97.6513 197.803C96.9325 193.147 95.784 189.691 95.7354 189.546C95.6341 189.245 95.4013 189.006 95.1027 188.898Z"
						fill="#2E2E47"
					/>
					<path
						d="M101.597 213.001C95.7626 216.911 66.0037 236.898 61.0728 241.144C62.3968 240.433 63.9009 240.234 65.3229 240.524C70.8142 238.129 95.3499 227.25 104.006 220.299C101.99 218.355 101.164 215.608 101.597 213.001Z"
						fill="#2E2E47"
					/>
					<path
						d="M107.405 271.086C107.405 271.086 102.755 261.508 99.4032 257.539C96.0519 253.569 94.3855 250.919 97.747 249.491C101.109 248.062 109.87 258.479 111.863 264.424C113.857 270.369 108.522 273.303 107.405 271.086Z"
						fill="#DEBDFF"
					/>
					<path
						d="M104.899 260.697C104.899 260.697 122.746 251.17 125.396 253.449C128.047 255.728 124.706 259.715 124.706 259.715C124.706 259.715 130.197 258.898 132.789 261.624C134.735 263.671 138.151 272.036 139.327 274.605C140.151 276.403 139.571 278.526 137.952 279.663C133.764 282.607 125.857 287.637 122.897 285.958C118.789 283.628 114.974 280.943 113.636 279.222C112.297 277.501 108.296 275.215 107.559 271.449C106.823 267.682 104.899 260.697 104.899 260.697Z"
						fill="#DEBDFF"
					/>
					<path
						d="M275.293 251.312C273.513 251.312 270.403 248.041 265.776 243.011C262.557 239.511 258.908 235.544 255.941 233.398C249.016 228.392 219.735 214.04 212.072 210.711C205.009 207.644 202.443 209.997 202.417 210.021C202.281 210.151 202.065 210.149 201.934 210.015C201.803 209.881 201.803 209.667 201.936 209.534C202.05 209.421 204.804 206.808 212.344 210.084C220.025 213.42 249.384 227.814 256.342 232.844C259.366 235.03 262.881 238.851 266.28 242.548C270.064 246.661 274.352 251.323 275.57 250.555C276.316 250.043 277.135 242.442 277.193 222.644C277.235 208.574 276.867 192.878 276.498 187.045C276.41 185.66 276.317 184.227 276.222 182.768C275.514 171.901 274.711 159.584 275.413 154.472C275.439 154.285 275.613 154.154 275.799 154.18C275.986 154.205 276.118 154.378 276.092 154.565C275.399 159.609 276.199 171.889 276.905 182.723C277 184.183 277.093 185.616 277.181 187.002C277.552 192.859 277.92 208.604 277.878 222.705C277.795 249.96 276.395 250.843 275.935 251.133C275.745 251.254 275.531 251.312 275.293 251.312Z"
						fill="#2E2E47"
					/>
					<path
						d="M242.954 27.798C242.891 27.798 242.828 27.7806 242.771 27.7446C237.921 24.6653 231.956 24.3998 228.392 24.2415C226.221 24.145 225.246 24.0843 224.857 23.6562C224.56 23.3289 224.403 22.8015 224.206 22.1334C223.666 20.3121 222.85 17.5599 218.494 15.7591C212.568 13.3094 201.162 14.1123 201.048 14.1207C200.863 14.1328 200.695 13.993 200.681 13.8046C200.667 13.6162 200.809 13.4522 200.998 13.4382C201.472 13.4038 212.682 12.6157 218.756 15.1266C223.414 17.0522 224.321 20.1112 224.862 21.9389C225.029 22.5 225.172 22.9847 225.364 23.1961C225.578 23.4311 227.024 23.4954 228.423 23.5577C232.055 23.7194 238.135 23.9897 243.138 27.1668C243.298 27.2681 243.345 27.4798 243.244 27.6392C243.178 27.7418 243.067 27.798 242.954 27.798Z"
						fill="#2E2E47"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_3356_18892">
					<rect width="390" height="420" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
