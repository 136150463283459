export default function LogoTrigo() {
	return (
		<svg
			width="176"
			height="64"
			viewBox="0 0 176 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.50383 4.20996L0.563232 6.91477V36.9316C0.563232 46.4275 5.06781 49.9585 17.3371 48.5873V40.466C12.1949 40.7437 9.50383 40.6751 9.50383 36.9316V22.5162H17.3371V13.915H9.50383V4.20996Z"
				fill="#F55A00"
			/>
			<path
				d="M32.2634 19.8767V13.9151H23.3193V48.5702H32.2634V32.0089C32.2634 28.6802 33.2918 26.325 35.4515 24.8681C37.607 23.4161 40.2271 22.8219 42.798 23.202V13.2227C38.0843 13.2227 33.926 15.4407 32.2634 19.8767Z"
				fill="#F55A00"
			/>
			<path
				d="M56.2055 13.915H47.2615V48.5736H56.2055V13.915Z"
				fill="#F55A00"
			/>
			<path
				d="M170.14 19.489C166.605 15.9546 162.238 14.1514 157.113 14.1514C151.988 14.1514 147.613 15.9546 144.086 19.489C140.558 23.0234 138.806 27.3154 138.806 32.4576C138.805 33.4903 138.88 34.5216 139.033 35.543V63.976L147.977 64V48.4705C150.768 50.019 153.918 50.8063 157.109 50.7536C162.251 50.7536 166.605 49.019 170.136 45.4846C173.667 41.9502 175.474 37.5827 175.474 32.4576C175.474 27.3326 173.674 23.0234 170.14 19.489ZM163.832 39.3139C162.023 41.0493 159.614 42.0183 157.108 42.0183C154.601 42.0183 152.192 41.0493 150.383 39.3139C148.652 37.5107 147.75 35.2242 147.75 32.4576C147.75 29.6911 148.652 27.3977 150.383 25.6014C152.193 23.8675 154.602 22.8996 157.108 22.8996C159.613 22.8996 162.023 23.8675 163.832 25.6014C165.635 27.4046 166.537 29.69 166.537 32.4576C166.537 35.2253 165.635 37.5107 163.832 39.3139Z"
				fill="#F55A00"
			/>
			<path
				d="M170.14 19.489C166.605 15.9546 162.238 14.1514 157.113 14.1514C151.988 14.1514 147.613 15.9546 144.086 19.489C140.558 23.0234 138.806 27.3154 138.806 32.4576C138.805 33.4903 138.88 34.5216 139.033 35.543V63.976L147.977 64V48.4705C150.768 50.019 153.918 50.8063 157.109 50.7536C162.251 50.7536 166.605 49.019 170.136 45.4846C173.667 41.9502 175.474 37.5827 175.474 32.4576C175.474 27.3326 173.674 23.0234 170.14 19.489ZM163.832 39.3139C162.023 41.0493 159.614 42.0183 157.108 42.0183C154.601 42.0183 152.192 41.0493 150.383 39.3139C148.652 37.5107 147.75 35.2242 147.75 32.4576C147.75 29.6911 148.652 27.3977 150.383 25.6014C152.193 23.8675 154.602 22.8996 157.108 22.8996C159.613 22.8996 162.023 23.8675 163.832 25.6014C165.635 27.4046 166.537 29.69 166.537 32.4576C166.537 35.2253 165.635 37.5107 163.832 39.3139Z"
				fill="url(#paint0_linear_2698_12825)"
			/>
			<path
				d="M127.425 13.8877C127.531 16.6542 127.425 31.145 127.425 31.8169C127.425 34.5891 126.523 36.8745 124.72 38.6732C122.913 40.4091 120.504 41.3786 117.998 41.3786C115.492 41.3786 113.083 40.4091 111.275 38.6732C109.54 36.87 108.639 34.5834 108.639 31.8169C108.639 31.1313 108.694 16.6542 108.797 13.8877H99.7942C99.7359 16.6576 99.7085 31.1621 99.7085 31.8169C99.7085 36.9591 101.44 41.3128 104.974 44.8438C108.509 48.3748 112.879 50.1129 118.001 50.1129C123.123 50.1129 127.497 48.3782 131.028 44.8438C134.559 41.3094 136.366 36.942 136.366 31.8169C136.366 31.1621 136.335 16.6576 136.273 13.8877H127.425Z"
				fill="#F55A00"
			/>
			<path
				d="M127.425 13.8877C127.531 16.6542 127.425 31.145 127.425 31.8169C127.425 34.5891 126.523 36.8745 124.72 38.6732C122.913 40.4091 120.504 41.3786 117.998 41.3786C115.492 41.3786 113.083 40.4091 111.275 38.6732C109.54 36.87 108.639 34.5834 108.639 31.8169C108.639 31.1313 108.694 16.6542 108.797 13.8877H99.7942C99.7359 16.6576 99.7085 31.1621 99.7085 31.8169C99.7085 36.9591 101.44 41.3128 104.974 44.8438C108.509 48.3748 112.879 50.1129 118.001 50.1129C123.123 50.1129 127.497 48.3782 131.028 44.8438C134.559 41.3094 136.366 36.942 136.366 31.8169C136.366 31.1621 136.335 16.6576 136.273 13.8877H127.425Z"
				fill="url(#paint1_linear_2698_12825)"
			/>
			<path
				d="M76.7743 14.0382C71.5943 14.5696 68.0188 15.7523 64.7758 19.129C61.5327 22.5057 59.877 26.5921 59.877 31.4292C59.877 36.2663 61.5053 40.3835 64.6764 43.7705C67.9331 47.0958 71.9372 48.7927 76.7571 48.7927C81.4365 48.7927 85.0361 47.0786 87.614 43.6505V47.3118C87.614 52.6734 84.2202 55.6593 78.7249 55.6593C74.5151 55.6593 71.5258 54.0995 69.8357 50.9113L62.3692 55.1862C65.3551 60.6129 71.1247 63.4652 78.4541 63.4652C83.3392 63.4652 87.4769 62.0939 90.9394 59.324C94.4018 56.554 96.1638 52.5397 96.1638 47.3255C96.1638 47.3255 96.0987 38.5563 96.1638 35.6355C96.4072 24.758 92.2386 19.7735 92.2386 19.7735C92.2386 19.7735 87.2404 12.9618 76.7743 14.0382ZM78.1455 40.7057C72.6605 40.7057 68.6427 36.8388 68.6427 31.412C68.6427 28.7655 69.5272 26.5235 71.2892 24.8266C73.1233 23.0645 75.3619 22.18 78.1455 22.18C80.9292 22.18 83.1643 23.0645 84.9298 24.8266C86.7604 26.5406 87.6449 28.7655 87.6449 31.412C87.6312 36.8388 83.6271 40.7057 78.1318 40.7057H78.1455Z"
				fill="#F55A00"
			/>
			<path
				d="M52.191 9.44796C54.8 9.44796 56.915 7.33296 56.915 4.72398C56.915 2.115 54.8 0 52.191 0C49.582 0 47.467 2.115 47.467 4.72398C47.467 7.33296 49.582 9.44796 52.191 9.44796Z"
				fill="#F55A00"
			/>
			<path
				d="M136.366 26.541C136.366 21.8924 136.325 15.6155 136.287 13.8877H127.425C127.483 15.3995 127.48 20.4217 127.466 24.772V25.0429C127.493 27.3475 127.883 29.6336 128.621 31.8169C132.17 42.3996 143.513 50.2385 157.154 50.7424L157.154 42.0159C146.215 41.4811 137.452 34.8576 136.366 26.541Z"
				fill="#F55A00"
			/>
			<path
				d="M136.366 26.541C136.366 21.8924 136.325 15.6155 136.287 13.8877H127.425C127.483 15.3995 127.48 20.4217 127.466 24.772V25.0429C127.493 27.3475 127.883 29.6336 128.621 31.8169C132.17 42.3996 143.513 50.2385 157.154 50.7424L157.154 42.0159C146.215 41.4811 137.452 34.8576 136.366 26.541Z"
				fill="url(#paint2_linear_2698_12825)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2698_12825"
					x1="157.14"
					y1="45"
					x2="168.58"
					y2="26.2858"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2698_12825"
					x1="136.366"
					y1="46.4308"
					x2="113.001"
					y2="35.8382"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_2698_12825"
					x1="157.154"
					y1="47.019"
					x2="136.946"
					y2="39.7211"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
}
