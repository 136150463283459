import { IconProps } from "./types";

export default function IcoDelete({
	color = "#773FAF",
	width = "20",
	height = "20"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.1458 14.1666H11.7708C11.9271 14.1666 12.0833 14.0364 12.0833 13.8541V8.22908C12.0833 8.07283 11.9271 7.91658 11.7708 7.91658H11.1458C10.9635 7.91658 10.8333 8.07283 10.8333 8.22908V13.8541C10.8333 14.0364 10.9635 14.1666 11.1458 14.1666ZM15.4167 5.41659H13.2552L12.3698 3.95825C12.1615 3.59367 11.7448 3.33325 11.3021 3.33325H8.67187C8.22916 3.33325 7.8125 3.59367 7.60416 3.95825L6.71875 5.41659H4.58333C4.34896 5.41659 4.16666 5.62492 4.16666 5.83325V6.24992C4.16666 6.48429 4.34896 6.66659 4.58333 6.66659H5V15.4166C5 16.1197 5.54687 16.6666 6.25 16.6666H13.75C14.4271 16.6666 15 16.1197 15 15.4166V6.66659H15.4167C15.625 6.66659 15.8333 6.48429 15.8333 6.24992V5.83325C15.8333 5.62492 15.625 5.41659 15.4167 5.41659ZM8.61979 4.66138C8.64583 4.63534 8.69791 4.58325 8.75 4.58325C8.75 4.58325 8.75 4.58325 8.77604 4.58325H11.224C11.276 4.58325 11.3281 4.63534 11.3542 4.66138L11.7969 5.41659H8.17708L8.61979 4.66138ZM13.75 15.4166H6.25V6.66659H13.75V15.4166ZM8.22916 14.1666H8.85416C9.01041 14.1666 9.16666 14.0364 9.16666 13.8541V8.22908C9.16666 8.07283 9.01041 7.91658 8.85416 7.91658H8.22916C8.04687 7.91658 7.91666 8.07283 7.91666 8.22908V13.8541C7.91666 14.0364 8.04687 14.1666 8.22916 14.1666Z"
				fill={color}
			/>
		</svg>
	);
}
