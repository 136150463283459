import { IconProps } from "./types";

export default function IcoEdit({
	color = "#773FAF",
	width = "24",
	height = "24"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.3438 6.3125L17.6563 4.625C16.8438 3.8125 15.5001 3.8125 14.6876 4.625L4.75006 14.5L4.00006 19.1562C3.90631 19.6562 4.34381 20.0938 4.84381 20L9.50006 19.25L19.3751 9.3125C20.1876 8.5 20.1876 7.15625 19.3438 6.3125ZM7.75006 13.4375L13.0626 8.15625L15.8438 10.9375L10.5626 16.25V14.9375H9.06256V13.4375H7.75006ZM6.56256 18.3438L5.65631 17.4375L6.06256 15.1562L6.62506 14.5625H7.93756V16.0625H9.43756V17.375L8.84381 17.9375L6.56256 18.3438ZM18.4063 8.34375L16.8751 9.875L14.1251 7.125L15.6563 5.59375C15.9376 5.3125 16.4063 5.3125 16.6876 5.59375L18.3751 7.28125C18.6876 7.59375 18.6876 8.0625 18.4063 8.34375Z"
				fill={color}
			/>
		</svg>
	);
}
