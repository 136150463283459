import styles from "./styles.module.scss";

import { AvatarProps } from "./types";

export default function Avatar({ src }: AvatarProps) {
	return (
		<div className={styles.container}>
			<img src={src} alt="" />
		</div>
	);
}
