import clsx from "clsx";

import styles from "./styles.module.scss";

import { TextInfoProps, TextSmallProps } from "./types";

export function TextInfo({ label, value }: TextInfoProps) {
	return (
		<div className={styles.container}>
			<span
				className={clsx(["overline_sm", "font-medium", "text-neutral-700"])}
			>
				{label}
			</span>
			<p className={clsx(["body", "text-neutral-400"])}>{value}</p>
		</div>
	);
}

export function TextSmall({ label, error }: TextSmallProps) {
	return (
		<span className={clsx(["text-sm", error ? "text-semantic-red_500" : ""])}>
			{label}
		</span>
	);
}
