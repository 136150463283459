import clsx from "clsx";

import styles from "./styles.module.scss";

import { ButtonProps } from "./types";

export default function Button({
	variant = "primary",
	children,
	className,
	...props
}: ButtonProps) {
	return (
		<button
			className={clsx([styles.container, "button", className], {
				[styles.primary]: variant === "primary",
				[styles.outline]: variant === "outline",
				[styles.text]: variant === "text",
				[styles.warningText]: variant === "warning-text",
				[styles.superPerfil]: variant === "super-perfil"
			})}
			{...props}
		>
			{children}
		</button>
	);
}
