import { LogoTrigo } from "assets/icons";

import styles from "./styles.module.scss";

import { RegistrationHeaderProps } from "./types";

export default function RegistrationHeader({
	name,
	description
}: RegistrationHeaderProps) {
	return (
		<div className={styles.container}>
			<LogoTrigo />
			<div>
				<p
					className="heading_lg"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: String(name)
					}}
				/>
				<p className="heading_sm">{description}</p>
			</div>
		</div>
	);
}
