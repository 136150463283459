import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import styles from "./styles.module.scss";

export default function SingleLayout() {
	return (
		<>
			<Helmet>
				<meta name="theme-color" content="#fff" />
			</Helmet>
			<div className={styles.containerSingleLayout}>
				<Outlet />
			</div>
		</>
	);
}
