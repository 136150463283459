import clsx from "clsx";

import { IcoSuperPerfil } from "assets/icons";

import styles from "./styles.module.scss";

import { StepsProps } from "./types";

export default function Steps({ active, steps, superProgress }: StepsProps) {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<p className="overline_sm">
					{active}
					<span>/{steps}</span>
				</p>
				<div className={styles.progress}>
					<div
						className={clsx([styles.step], {
							[styles.active]: active >= 1
						})}
					/>
					<div
						className={clsx([styles.step], {
							[styles.active]: active >= 2
						})}
					/>
					<div className={clsx([styles.step, styles.super])}>
						<div
							className={clsx([styles.ico], {
								[styles.active]: superProgress === 100
							})}
						>
							<IcoSuperPerfil />
						</div>
						{active >= 3 && (
							<div
								className={clsx([styles.step, styles.super], {
									[styles.active]: active >= 3
								})}
								style={{ width: `calc(${superProgress}% - 1.5rem)` }}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
