import clsx from "clsx";

import { IcoEnter } from "assets/icons";

import { ServiceType } from "store/features/registration/types";
import { ListItemProps } from "./types";

import styles from "./styles.module.scss";

export default function ListItem({
	image,
	name,
	categoryId,
	selectedServices = [],
	onClick
}: ListItemProps) {
	const filterSelectedServices = (() => {
		return selectedServices.filter((service) => {
			return service.category === categoryId;
		});
	})();

	return (
		<button
			className={clsx([styles.container], {
				[styles.active]: filterSelectedServices.length
			})}
			onClick={onClick}
		>
			<div>
				<div className={styles.icon}>
					<img src={image} alt={name} />
				</div>
				<div className="flex flex-col gap-1">
					<p className="title text-left">{name}</p>
					<p className="body text-neutral-600 text-left">
						{filterSelectedServices.length > 0 &&
							filterSelectedServices?.map((service: any, idx: number) => (
								<span key={`service-${idx}`}>
									{service.service_name}
									{idx < filterSelectedServices.length - 2 && ", "}
									{idx === filterSelectedServices.length - 2 && " e "}
								</span>
							))}
					</p>
				</div>
			</div>
			<div className={styles.icon}>
				<IcoEnter />
			</div>
		</button>
	);
}
