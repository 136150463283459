import OrderExpired from "assets/icons/order-expired";
import styles from "./styles.module.scss";

export default function BudgetRequestExpiredPage() {
	return (
		<div className={styles.successContainer}>
			<h1 className="display_lg mb-3 text-center">Link expirado</h1>
			<p className="text-lg font-normal text-center px-10 mb-6">
				Esse pedido de orçamento não está mais disponível
			</p>
			<OrderExpired className="mt-7" />
		</div>
	);
}
