import { IconProps } from "./types";

export default function IcoSuperPerfil({
	color = "#3A3736",
	width = "16",
	height = "16"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1419_3854)">
				<path
					d="M10.3751 5.59375C10.2501 5.46875 10.0626 5.46875 9.93756 5.59375L7.21881 8.28125L6.03131 7.09375C5.90631 6.96875 5.71881 6.96875 5.59381 7.09375L4.87506 7.8125C4.75006 7.9375 4.75006 8.15625 4.87506 8.25L7.00006 10.4062C7.09381 10.5312 7.31256 10.5312 7.43756 10.4062L11.0938 6.75C11.2188 6.625 11.2188 6.4375 11.0938 6.3125L10.3751 5.59375ZM16.0001 8C16.0001 6.875 15.4063 5.875 14.5313 5.3125C14.7501 4.28125 14.4376 3.15625 13.6563 2.34375C12.8438 1.5625 11.7188 1.25 10.6876 1.46875C10.1251 0.59375 9.12506 0 8.00006 0C6.84381 0 5.84381 0.59375 5.28131 1.46875C4.25006 1.25 3.12506 1.5625 2.34381 2.34375C1.53131 3.15625 1.21881 4.28125 1.43756 5.3125C0.562561 5.875 6.10352e-05 6.875 6.10352e-05 8C6.10352e-05 9.15625 0.562561 10.1562 1.43756 10.7188C1.21881 11.75 1.53131 12.875 2.34381 13.6562C3.12506 14.4688 4.25006 14.7812 5.28131 14.5625C5.84381 15.4375 6.84381 16 8.00006 16C9.12506 16 10.1251 15.4375 10.6876 14.5625C11.7188 14.7812 12.8438 14.4688 13.6563 13.6562C14.4376 12.875 14.7501 11.75 14.5313 10.7188C15.4063 10.1562 16.0001 9.15625 16.0001 8ZM12.5938 9.9375C12.8751 10.5625 13.5626 11.6562 12.5938 12.625C11.7188 13.5 10.8438 13.0625 9.90631 12.625C9.65631 13.2812 9.34381 14.5 8.00006 14.5C6.56256 14.5 6.28131 13.1875 6.06256 12.625C5.40631 12.9375 4.34381 13.5625 3.37506 12.5938C2.37506 11.5938 3.12506 10.4688 3.37506 9.9375C2.71881 9.6875 1.50006 9.375 1.50006 8C1.50006 6.59375 2.81256 6.3125 3.37506 6.09375C3.09381 5.46875 2.43756 4.375 3.40631 3.40625C4.40631 2.40625 5.53131 3.15625 6.06256 3.40625C6.31256 2.75 6.62506 1.5 8.00006 1.5C9.40631 1.5 9.68756 2.84375 9.90631 3.40625C10.5313 3.125 11.6251 2.46875 12.5938 3.4375C13.5938 4.4375 12.8438 5.5625 12.5938 6.09375C13.2501 6.34375 14.4688 6.65625 14.4688 8.03125C14.4688 9.4375 13.1563 9.71875 12.5938 9.9375Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_1419_3854">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
