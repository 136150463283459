import { IconProps } from "./types";

export default function IcoCalendar({
	color = "#272A30",
	width = "24",
	height = "24"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.625 13C9.8125 13 10 12.8438 10 12.625V11.375C10 11.1875 9.8125 11 9.625 11H8.375C8.15625 11 8 11.1875 8 11.375V12.625C8 12.8438 8.15625 13 8.375 13H9.625ZM13 12.625V11.375C13 11.1875 12.8125 11 12.625 11H11.375C11.1562 11 11 11.1875 11 11.375V12.625C11 12.8438 11.1562 13 11.375 13H12.625C12.8125 13 13 12.8438 13 12.625ZM16 12.625V11.375C16 11.1875 15.8125 11 15.625 11H14.375C14.1562 11 14 11.1875 14 11.375V12.625C14 12.8438 14.1562 13 14.375 13H15.625C15.8125 13 16 12.8438 16 12.625ZM13 15.625V14.375C13 14.1875 12.8125 14 12.625 14H11.375C11.1562 14 11 14.1875 11 14.375V15.625C11 15.8438 11.1562 16 11.375 16H12.625C12.8125 16 13 15.8438 13 15.625ZM10 15.625V14.375C10 14.1875 9.8125 14 9.625 14H8.375C8.15625 14 8 14.1875 8 14.375V15.625C8 15.8438 8.15625 16 8.375 16H9.625C9.8125 16 10 15.8438 10 15.625ZM16 15.625V14.375C16 14.1875 15.8125 14 15.625 14H14.375C14.1562 14 14 14.1875 14 14.375V15.625C14 15.8438 14.1562 16 14.375 16H15.625C15.8125 16 16 15.8438 16 15.625ZM19 7.5C19 6.6875 18.3125 6 17.5 6H16V4.375C16 4.1875 15.8125 4 15.625 4H14.375C14.1562 4 14 4.1875 14 4.375V6H10V4.375C10 4.1875 9.8125 4 9.625 4H8.375C8.15625 4 8 4.1875 8 4.375V6H6.5C5.65625 6 5 6.6875 5 7.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V7.5ZM17.5 18.3125C17.5 18.4375 17.4062 18.5 17.3125 18.5H6.6875C6.5625 18.5 6.5 18.4375 6.5 18.3125V9H17.5V18.3125Z"
				fill={color}
			/>
		</svg>
	);
}
