import clsx from "clsx";
import styles from "./styles.module.scss";

export interface BadgeProps {
	label: string;
	type?: "timestamp" | "order" | "category";
}

export default function Badge({ label, type = "order" }: BadgeProps) {
	return (
		<span
			className={clsx(
				[styles.badgeWrapper, "overline_sm", "font-semibold", "leading-none"],
				{
					[styles.badgeOrderRequest]: type === "order",
					[styles.badgeTimestamp]: type === "timestamp",
					[styles.badgeCategory]: type === "category"
				}
			)}
		>
			<h1 className="overline_sm">{label}</h1>
		</span>
	);
}
