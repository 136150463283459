import { useState } from "react";
import * as Yup from "yup";
import { IcoCalendar } from "assets/icons";
import DatePicker from "react-datepicker";
import {
	Button,
	CertificationItem,
	Checkbox,
	Content,
	Input,
	ShallowInput
} from "components";
import { useFormik } from "formik";
import { store } from "store";
import { CertificationType } from "store/features/registration/types";
import { format } from "date-fns";

export interface CertificationProps {
	certifications?: any[];
	setCertifications?: any;
}

export default function Certifications({
	certifications,
	setCertifications
}: CertificationProps) {
	const currentDate = new Date();
	const personalData = store.getState().registration;
	const initialValues = {
		description: "",
		certifier: "",
		start_year: "",
		end_year: "",
		service_provider: personalData.id
	};

	const [ongoingEducation, setOngoingEducation] = useState(false);
	const [formIsOpen, setFormIsOpen] = useState(false);

	const CertificationSchema = Yup.object().shape({
		description: Yup.string().required("Campo obrigatório"),
		certifier: Yup.string().required("Campo obrigatório"),
		start_year: Yup.date(),
		end_year: Yup.date()
	});
	const formik = useFormik({
		initialValues,
		validationSchema: CertificationSchema,
		onSubmit: () => {}
	});

	const saveCertification = () => {
		if (formik.isValid) {
			const { start_year, end_year, ...otherFormikValues } = formik.values;
			const newCertification = {
				...otherFormikValues,
				start_year: format(new Date(start_year), "yyyy"),
				end_year:
					ongoingEducation || !end_year ? 0 : format(new Date(end_year), "yyyy")
			};
			if (certifications?.length) {
				setCertifications([...certifications, newCertification]);
			} else {
				setCertifications([newCertification]);
			}
			formik.resetForm();
			setOngoingEducation(false);
			setFormIsOpen(false);
		}
	};

	const removeCertification = (index: any) => {
		certifications?.splice(index, 1);
		if (certifications?.length) {
			setCertifications([...certifications]);
		} else {
			setCertifications([]);
		}
	};

	return (
		<div className="flex flex-col gap-4 mb-10">
			<div>
				<h3 className="heading_sm">Certificações</h3>
				<p className="body text-neutral-500">
					Liste todas as certificações que você já concluiu e as que estão em
					andamento
				</p>
			</div>
			<Button
				variant="outline"
				type="button"
				onClick={() => setFormIsOpen(!formIsOpen)}
			>
				Nova certificação
			</Button>
			{formIsOpen && (
				<Content>
					<Input
						label="NOME DA FORMAÇÃO"
						id="description"
						name="description"
						placeholder="Digite a formação aqui"
						value={formik.values.description}
						error={formik.touched.description && formik.errors.description}
						onChange={formik.handleChange}
					/>
					<Input
						label="NOME DA INSTITUIÇÃO"
						id="certifier"
						name="certifier"
						placeholder="Digite a instituição aqui"
						value={formik.values.certifier}
						error={formik.touched.certifier && formik.errors.certifier}
						onChange={formik.handleChange}
					/>

					<DatePicker
						selected={
							(formik.values.start_year &&
								new Date(formik.values.start_year)) ||
							null
						}
						onChange={(val) => {
							formik.setFieldValue("start_year", val);
						}}
						onBlur={formik.handleBlur}
						wrapperClassName="w-full"
						showYearPicker
						dateFormat="yyyy"
						placeholderText="ano"
						customInput={
							<ShallowInput
								label="ANO DE INÍCIO DA FORMAÇÃO"
								id="start_year"
								name="start_year"
								placeholder="0000"
								error={formik.touched.start_year && formik.errors.start_year}
								leading={<IcoCalendar />}
							/>
						}
					/>
					<DatePicker
						selected={
							(formik.values.end_year && new Date(formik.values.end_year)) ||
							null
						}
						onChange={(val) => {
							formik.setFieldValue("end_year", val);
						}}
						onBlur={formik.handleBlur}
						wrapperClassName="w-full"
						showYearPicker
						dateFormat="yyyy"
						disabled={ongoingEducation}
						placeholderText="ano"
						customInput={
							<ShallowInput
								label="ANO DE FIM DA FORMAÇÃO"
								id="end_year"
								name="end_year"
								placeholder="0000"
								error={formik.touched.end_year && formik.errors.end_year}
								leading={<IcoCalendar />}
							/>
						}
					/>
					<Checkbox
						label={<span>Formação em andamento</span>}
						id="ongoing"
						name="ongoing"
						value="1"
						checked={ongoingEducation}
						onChange={() => {
							setOngoingEducation(!ongoingEducation);
						}}
					/>
					<Button
						variant="text"
						type="button"
						onClick={saveCertification}
						disabled={!formik.isValid || !formik.dirty}
					>
						Salvar
					</Button>
				</Content>
			)}
			{certifications?.map((cert, index) => {
				const certification = cert as CertificationType;
				return (
					<CertificationItem
						key={`${certification.description}-${index}`}
						title={certification.description || ""}
						institution={certification.certifier || ""}
						duration={`${certification.start_year} - ${
							certification.end_year === 0 ? "Hoje" : certification.end_year
						}`}
						onDelete={() => {
							removeCertification(index);
						}}
					/>
				);
			})}
		</div>
	);
}
