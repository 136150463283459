import clsx from "clsx";

import { CheckboxProps } from "./types";

import "./styles.scss";

export default function Checkbox({
	name,
	id,
	label,
	value,
	checked,
	disabled,
	onChange,
	error,
	onBlur
}: CheckboxProps) {
	return (
		<div
			className={clsx("container-checkbox", {
				error
			})}
		>
			<input
				type="checkbox"
				id={id}
				name={name}
				value={value}
				checked={checked}
				disabled={disabled}
				onChange={onChange}
				onBlur={onBlur}
			/>
			<label htmlFor={id} className="body">
				{label}
			</label>
		</div>
	);
}
