export default function OrderRefused(props: any) {
	return (
		<svg
			width="390"
			height="420"
			viewBox="0 0 390 420"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_3429_10368)">
				<path
					d="M93.3956 249.38C93.3956 249.38 94.0786 295.109 124.053 291.876C154.027 288.642 126.298 244.807 126.298 244.807L93.3956 249.38Z"
					fill="#DEBDFF"
				/>
				<path
					d="M90.8562 193.198C90.8562 193.198 87.1273 236.205 87.6495 259.139C87.6495 259.139 94.216 260.882 110.645 259.139C127.074 257.398 90.8562 193.198 90.8562 193.198Z"
					fill="#F55A00"
				/>
				<path
					d="M160.222 7.84912L163.724 13.01L28.8817 133.16L229.772 358.616L370.179 233.507L169.289 8.05148L160.222 7.84912Z"
					fill="#6D3412"
				/>
				<path
					d="M160.226 7.83069L19.8204 132.941L220.713 358.394L361.119 233.284L160.226 7.83069Z"
					fill="#CC9270"
				/>
				<path
					d="M158.646 20.3596L168.404 31.3107L101.654 210.531L32.4203 132.831L158.646 20.3596Z"
					fill="#9FE2FF"
				/>
				<path
					d="M179.617 43.895L189.307 54.7694L122.561 233.994L112.868 223.116L179.617 43.895Z"
					fill="#9FE2FF"
				/>
				<path
					d="M130.271 242.647L197.017 63.4229L348.504 233.434L222.279 345.906L130.271 242.647Z"
					fill="#9FE2FF"
				/>
				<path
					d="M168.403 31.3108L179.617 43.8957L112.868 223.116L101.654 210.531L168.403 31.3108Z"
					fill="white"
				/>
				<path
					d="M189.307 54.77L197.017 63.4232L130.271 242.647L122.561 233.994L189.307 54.77Z"
					fill="white"
				/>
				<path
					d="M94.4452 71.149L90.2736 74.8662L286.072 294.602L290.243 290.885L94.4452 71.149Z"
					fill="#CC9270"
				/>
				<path
					d="M196.912 196.032L290.235 185.501L281.513 174.682L177.333 166.723L158.059 183.231L186.244 305.463L239.033 342.082L239.388 297.526L196.912 196.032Z"
					fill="#7FA7D4"
				/>
				<path
					d="M216.959 187.346C216.959 187.346 221.779 211.954 220.449 220.284L194.357 218.405L187.16 194.295L213.022 187.346L216.959 187.346Z"
					fill="#7FA7D4"
				/>
				<path
					d="M203.418 213.5C203.418 213.5 208.249 207.086 204.609 176.824L203.418 149.958L183.54 164.22C183.54 164.22 182.199 175.978 183.54 179.561C184.881 183.144 190.3 195.019 190.463 195.594C190.627 196.169 203.418 213.5 203.418 213.5Z"
					fill="#7F2F00"
				/>
				<path
					d="M301.044 184.866C300.513 184.814 300.024 184.801 299.591 184.819C293.594 185.094 266.317 187.822 249.135 187.273C231.948 186.728 193.994 182.892 193.994 182.892L197.016 151.88C197.016 151.88 235.496 155.088 247.771 155.637C258.564 156.117 279.909 158.557 297.515 164.219C299.926 169.472 302.627 177.504 301.044 184.866Z"
					fill="#DEBDFF"
				/>
				<path
					d="M344.87 200.097C344.595 201.186 343.31 202.82 340.409 200.097C337.503 197.368 329.052 192.185 325.234 191.64C321.69 191.133 307.819 185.466 301.044 184.865C302.627 177.504 299.927 169.472 297.515 164.219C299.927 164.992 302.269 165.825 304.504 166.724C323.05 174.184 324.415 172 327.958 175.818C331.506 179.636 333.141 183.231 333.141 183.231C333.141 183.231 345.145 199.002 344.87 200.097Z"
					fill="#F55A00"
				/>
				<path
					d="M155.32 52.9765C155.32 52.9765 160.991 61.3486 156.014 73.0289C151.038 84.7088 135.736 87.8786 135.736 87.8786L127.612 74.1143L124.053 57.8359L136.928 50.6119L144.198 48.1328L155.32 52.9765Z"
					fill="#5E328B"
				/>
				<path
					d="M88.8145 104.05L91.9102 115.766C91.9102 115.766 97.855 130.563 100.598 132.582C103.341 134.601 120.025 133.81 120.025 133.81L125.108 124.611L118.454 108.771L115.253 94.0647L87.6501 97.9972L88.8145 104.05Z"
					fill="#393253"
				/>
				<path
					d="M118.152 171.396L147.853 165.426L138.607 126.06L133.956 106.276L108.115 111.471L113.094 141.193L118.152 171.396Z"
					fill="#DEBDFF"
				/>
				<path
					d="M111.548 123.655L141.748 129.129C141.748 129.129 144.034 141.32 133.278 142.872C121.53 144.566 110.645 123.195 110.645 123.195L111.548 123.655Z"
					fill="#2E2E47"
				/>
				<path
					d="M107.671 66.4731C130.715 52.7855 139.686 68.238 149.39 88.1752C158.992 107.902 160.873 129.21 148.306 134.885C135.879 140.496 116.454 133.743 105.13 114.679C93.8065 95.6151 84.6269 80.1607 107.671 66.4731Z"
					fill="#DEBDFF"
				/>
				<path
					d="M113.01 122.613C115.477 119.349 113.301 113.546 108.15 109.652C102.998 105.758 96.8217 105.247 94.3543 108.511C91.887 111.775 94.0631 117.578 99.2147 121.472C104.366 125.367 110.543 125.877 113.01 122.613Z"
					fill="#DEBDFF"
				/>
				<path
					d="M107.012 120.183C106.917 120.183 106.82 120.154 106.737 120.093C100.535 115.549 102.754 110.553 102.778 110.504C102.886 110.27 103.162 110.169 103.396 110.277C103.629 110.385 103.73 110.661 103.623 110.894C103.539 111.079 101.729 115.27 107.287 119.342C107.495 119.494 107.54 119.785 107.388 119.993C107.297 120.117 107.156 120.183 107.012 120.183Z"
					fill="#2E2E47"
				/>
				<path
					d="M146.111 92.0022C146.861 94.2022 148.457 95.6483 149.677 95.2324C150.896 94.8166 151.277 92.6963 150.527 90.4963C149.777 88.2967 148.18 86.8502 146.961 87.2661C145.741 87.6816 145.361 89.8022 146.111 92.0022ZM129.203 94.3402C130.033 96.5113 131.682 97.8976 132.885 97.4375C134.088 96.9773 134.391 94.8443 133.561 92.6732C132.731 90.5025 131.083 89.1157 129.879 89.5759C128.676 90.0365 128.373 92.1695 129.203 94.3402Z"
					fill="#371D14"
				/>
				<path
					d="M144.588 105.15C144.481 105.15 144.374 105.113 144.286 105.038C144.09 104.872 144.067 104.578 144.233 104.382L147.765 100.237L143.515 97.0247C143.485 97.0011 143.457 96.9746 143.433 96.9441L138.79 91.1477C138.629 90.9473 138.661 90.654 138.862 90.4933C139.064 90.3318 139.356 90.3653 139.517 90.5657L144.124 96.3166L148.721 99.792C148.822 99.8695 148.888 99.9855 148.902 100.113C148.916 100.24 148.877 100.368 148.794 100.465L144.943 104.986C144.851 105.094 144.719 105.15 144.588 105.15Z"
					fill="#2E2E47"
				/>
				<path
					d="M139.71 109.595C139.71 109.595 141.767 114.004 147.616 112.781C153.466 111.559 153.585 106.695 153.585 106.695L150.783 107.607C148.142 108.466 145.406 109.038 142.643 109.308L139.71 109.595Z"
					fill="white"
				/>
				<path
					d="M146.438 82.6135C145.318 82.7657 144.229 83.0236 143.168 83.3466C142.538 83.5385 141.872 83.1835 141.68 82.5534C141.488 81.9236 141.843 81.2572 142.473 81.0652C142.637 81.0155 142.803 81.0024 142.963 81.0217L142.989 81.0251C144.187 81.1747 145.371 81.4013 146.509 81.7347C146.748 81.8045 146.884 82.0546 146.815 82.2932C146.763 82.469 146.614 82.5892 146.444 82.6127L146.438 82.6135ZM123 87.1203C123.257 86.6281 123.541 86.2874 123.873 85.9355C124.196 85.5882 124.556 85.2856 124.928 84.9993C125.677 84.4335 126.502 83.9891 127.353 83.6218C128.202 83.2394 129.087 82.9766 129.977 82.731L131.324 82.4343L132.683 82.2146C133.32 82.1117 133.92 82.5445 134.023 83.1816C134.126 83.8187 133.693 84.4184 133.056 84.5217C133.004 84.5302 132.953 84.5348 132.902 84.5364L132.839 84.5379L131.584 84.566L130.337 84.6643C129.516 84.7742 128.688 84.8983 127.896 85.1318C127.101 85.3508 126.323 85.6402 125.609 86.0388C125.256 86.2407 124.909 86.4581 124.596 86.7129C124.285 86.9522 123.972 87.2659 123.782 87.5265L123.741 87.5816C123.598 87.7766 123.324 87.819 123.129 87.6764C122.952 87.5465 122.901 87.3087 123 87.1203Z"
					fill="#393253"
				/>
				<path
					d="M139.545 70.8608C139.545 70.8608 140.254 71.558 140.146 73.5869C140.038 75.6154 135.278 72.3701 133.184 68.6581C133.184 68.6581 133.884 75.7387 126.093 74.9567C126.093 74.9567 120.079 79.6723 116.926 78.5735C113.772 77.4746 115.571 74.6009 115.571 74.6009L109.493 82.4898C109.493 82.4898 109.115 88.1166 106.527 91.5611C106.527 91.5611 107.094 101.012 108.301 103.256C109.509 105.5 111.654 109.194 111.654 109.194L105.131 114.68L94.9573 99.1675L95.0779 91.1672L104.13 72.811L110.799 65.8899L116.098 62.5171L137.105 64.6662L139.545 70.8608Z"
					fill="#393253"
				/>
				<path
					d="M129.109 64.7298C129.109 64.7298 107.232 79.295 103.032 87.0432C98.8312 94.7914 90.1718 111.075 90.1718 111.075C90.1718 111.075 84.9097 101.816 83.4709 96.8257C82.0325 91.8348 85.7934 77.9202 87.7757 73.8231C89.7579 69.7261 104.402 57.1011 109.358 56.2004C114.315 55.3 128.438 59.4321 128.438 59.4321L129.109 64.7298Z"
					fill="#A984CE"
				/>
				<path
					d="M141.462 72.4593C141.462 72.4593 133.982 62.7878 115.571 74.6003C115.571 74.6003 120.732 52.0032 132.991 54.8542C145.25 57.7052 141.462 72.4593 141.462 72.4593Z"
					fill="#5E328B"
				/>
				<path
					d="M107.223 81.8815C107.223 81.8815 122.482 52.151 133.134 48.0362C143.786 43.9214 150.281 47.2048 155.321 52.9765C155.321 52.9765 146.909 50.8289 140.936 52.0793C134.963 53.3292 125.278 55.5604 123.234 61.2728C121.188 66.9852 116.955 77.6911 107.223 81.8815Z"
					fill="#5E328B"
				/>
				<path
					d="M117.447 73.5984C117.379 73.5984 117.31 73.5834 117.243 73.5514C117.013 73.4385 116.917 73.1598 117.029 72.9286C117.087 72.8095 122.905 60.9341 128.995 52.9073C135.223 44.6997 145.598 46.1404 145.702 46.1558C145.956 46.194 146.131 46.4306 146.094 46.6846C146.056 46.939 145.815 47.1163 145.565 47.077C145.466 47.0619 135.626 45.7095 129.737 53.47C123.702 61.4232 117.923 73.2184 117.866 73.3367C117.786 73.5021 117.62 73.5984 117.447 73.5984Z"
					fill="#A984CE"
				/>
				<path
					d="M91.7626 82.3572C91.5976 82.3572 91.4373 82.2693 91.3529 82.114C89.5602 78.814 89.1024 75.5883 89.0842 75.4527C89.0492 75.1979 89.2269 74.9632 89.4816 74.9285C89.7429 74.8923 89.9719 75.0715 90.0066 75.3262C90.0112 75.3579 90.4637 78.5253 92.1715 81.6696C92.2941 81.8958 92.2108 82.1783 91.9846 82.3013C91.9136 82.339 91.8377 82.3572 91.7626 82.3572Z"
					fill="#5E328B"
				/>
				<path
					d="M100.084 145.85C98.7606 143.203 100.135 140.003 102.966 139.143C106.608 138.035 111.446 136.948 115.202 137.482C122.127 138.467 141.778 141.164 143.797 141.025C145.815 140.887 153.586 151.881 153.586 151.881L100.858 151.743L100.288 146.259L100.084 145.85Z"
					fill="#F55A00"
				/>
				<path
					d="M100.858 151.743L141.056 146.259H166.959L203.417 149.958C203.417 149.958 195.414 189.561 203.417 213.5H181.186L177.333 262.339H155.101L110.645 238.329L94.9559 209.28C94.9567 209.28 95.8193 164.753 100.858 151.743Z"
					fill="#F55A00"
				/>
				<path
					d="M147.853 146.259C147.853 146.259 141.6 146.881 139.153 149.958C136.706 153.035 131.314 199.36 132.761 204.299C134.208 209.237 165.119 252.013 166.368 252.637C167.617 253.261 181.187 213.5 181.187 213.5H193.22C193.22 213.5 226.941 278.153 235.059 309.732C235.059 309.732 249.59 429.805 253.592 450.24C257.594 470.674 253.592 579.39 253.592 579.39C253.592 579.39 222.337 588.15 193.944 575.767C193.944 575.767 197.567 507.358 188.896 456.001L153.585 557.38C153.585 557.38 120.974 568.719 99.5767 556.046C99.5767 556.046 121.915 506.024 123.391 496.019C124.867 486.015 149.187 423.32 149.187 423.32V405.311C149.187 405.311 139.432 361.009 139.153 337.473C139.153 337.473 120.434 313.937 110.645 278.588C110.645 278.588 92.1149 236.87 90.6541 207.042C89.193 177.214 100.858 151.743 100.858 151.743C100.858 151.743 91.9402 189.781 97.4349 204.299L119.641 200.762C119.641 200.762 118.747 154.162 131.272 147.594C131.272 147.594 138.216 143.487 147.853 146.259Z"
					fill="#FFAD7D"
				/>
				<path
					d="M188.896 456.467C188.666 456.467 188.465 456.296 188.434 456.061C185.417 432.739 181.921 410.132 181.589 407.995C170.726 398.082 149.661 353.731 148.766 351.841C148.657 351.608 148.756 351.331 148.988 351.221C149.221 351.11 149.498 351.21 149.608 351.442C149.828 351.905 171.648 397.852 182.334 407.413C182.414 407.484 182.467 407.582 182.484 407.688C182.521 407.924 186.201 431.541 189.358 455.941C189.391 456.196 189.211 456.43 188.956 456.463C188.936 456.466 188.916 456.467 188.896 456.467Z"
					fill="#D19136"
				/>
				<path
					d="M184.888 341.269C184.732 341.269 184.58 341.191 184.492 341.05C184.356 340.831 184.423 340.544 184.641 340.408C184.761 340.334 196.727 332.751 208.549 314.737C208.69 314.522 208.979 314.462 209.193 314.603C209.408 314.744 209.468 315.033 209.327 315.248C197.384 333.447 185.255 341.123 185.134 341.198C185.057 341.246 184.972 341.269 184.888 341.269Z"
					fill="#D19136"
				/>
				<path
					d="M177.333 262.805C177.285 262.805 177.237 262.797 177.188 262.781C176.944 262.701 176.811 262.438 176.891 262.193C183.545 241.935 180.752 213.829 180.723 213.548C180.697 213.292 180.883 213.064 181.139 213.037C181.393 213.007 181.623 213.197 181.65 213.453C181.679 213.736 184.491 242.04 177.775 262.484C177.711 262.68 177.529 262.805 177.333 262.805Z"
					fill="#D19136"
				/>
				<path
					d="M132.353 468.667C132.188 468.667 132.028 468.579 131.943 468.423C131.82 468.198 131.904 467.915 132.13 467.792C149.178 458.524 182.724 417.094 183.062 416.677C183.223 416.477 183.516 416.445 183.716 416.607C183.916 416.768 183.948 417.061 183.786 417.261C183.449 417.68 149.785 459.254 132.575 468.61C132.505 468.649 132.428 468.667 132.353 468.667Z"
					fill="#D19136"
				/>
				<path
					d="M190.464 417.435C190.337 417.435 190.212 417.384 190.12 417.284L181.68 408.075C181.507 407.885 181.519 407.59 181.709 407.417C181.899 407.243 182.193 407.256 182.367 407.445L190.807 416.655C190.98 416.844 190.968 417.139 190.778 417.313C190.689 417.395 190.576 417.435 190.464 417.435Z"
					fill="#D19136"
				/>
				<path
					d="M153.585 221.598C153.4 221.598 153.224 221.487 153.152 221.303C153.058 221.064 153.175 220.794 153.415 220.699L172.778 213.067C172.833 213.045 172.891 213.034 172.95 213.034H181.187C181.444 213.034 181.652 213.243 181.652 213.5C181.652 213.757 181.444 213.965 181.187 213.965H173.038L153.757 221.566C153.7 221.588 153.642 221.598 153.585 221.598Z"
					fill="#7F2F00"
				/>
				<path
					d="M100.921 152.203L100.796 151.28L112.805 149.642C115.09 149.33 118.026 148.635 121.135 147.899C126.312 146.674 131.664 145.406 134.613 145.796C134.868 145.829 135.047 146.063 135.013 146.319C134.98 146.573 134.746 146.754 134.49 146.719C131.711 146.352 126.444 147.6 121.349 148.805C118.218 149.546 115.26 150.246 112.931 150.564L100.921 152.203Z"
					fill="#7F2F00"
				/>
				<path
					d="M160.827 236.648C160.647 236.648 160.475 236.543 160.399 236.367C160.298 236.131 160.406 235.856 160.642 235.755C168.007 232.574 174.537 217.288 174.602 217.134C174.702 216.897 174.974 216.787 175.212 216.887C175.449 216.987 175.559 217.26 175.459 217.497C175.188 218.14 168.716 233.283 161.011 236.61C160.951 236.636 160.888 236.648 160.827 236.648Z"
					fill="#7F2F00"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3429_10368">
					<rect width="390" height="420" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
