import { IconProps } from "./types";

export default function IcoEyeSlash({
	color = "#773FAF",
	width = "24",
	height = "24"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.8125 18.7188L3.125 4.125C3.03125 4.0625 2.90625 4.03125 2.8125 4.03125C2.625 4.03125 2.5 4.09375 2.40625 4.1875L2.09375 4.59375C2.03125 4.6875 1.96875 4.78125 1.96875 4.90625C1.96875 5.0625 2.0625 5.21875 2.1875 5.28125L20.875 19.9062C20.9375 19.9688 21.0625 20 21.1562 20C21.3438 20 21.4688 19.9375 21.5625 19.8125L21.875 19.4375C21.9375 19.3438 22 19.25 22 19.125C22 18.9688 21.9062 18.8125 21.8125 18.7188ZM11.25 8.59375L15.4688 11.875C15.4062 10 13.875 8.5 12 8.5C11.75 8.53125 11.5 8.53125 11.25 8.59375ZM12.7188 15.4375L8.5 12.1562C8.5625 14.0312 10.0938 15.5 12 15.5C12.2188 15.5 12.4688 15.5 12.7188 15.4375ZM12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C19.0312 12.7188 18.5938 13.3438 18.0312 13.9062L19.2188 14.8125C19.875 14.125 20.4375 13.3438 20.875 12.4688C20.9375 12.3438 21 12.1875 21 12.0312C21 11.8438 20.9375 11.6875 20.875 11.5625C19.1875 8.25 15.8125 6 12 6C10.8438 6 9.75 6.21875 8.71875 6.59375L10.1562 7.75C10.75 7.59375 11.375 7.5 12 7.5ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C4.9375 11.3125 5.375 10.6875 5.9375 10.125L4.75 9.21875C4.09375 9.90625 3.53125 10.6875 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C13.125 18 14.2188 17.7812 15.25 17.4375L13.8125 16.2812C13.2188 16.4375 12.5938 16.5 12 16.5Z"
				fill={color}
			/>
		</svg>
	);
}
