import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import { registerLocale } from "react-datepicker";
import { initializeFirebase } from "services/firebase";
import Routers from "./router";

export default function App() {
	registerLocale("pt-BR", ptBR);
	initializeFirebase();
	return (
		<>
			<Router>
				<Helmet>
					<title>{`${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<Routers />
			</Router>
			<ToastContainer />
		</>
	);
}
