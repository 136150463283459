import OrderSent from "assets/icons/order-sent";
import styles from "./styles.module.scss";

export default function BudgetRequestMessageSentPage() {
	return (
		<div className={styles.successContainer}>
			<h1 className="display_lg mb-3 text-center">
				Proposta
				<br /> Enviada!
			</h1>
			<p className="text-lg font-normal text-center px-10 mb-6">
				Sua proposta foi enviada para o cliente. <br /> Aguarde uma nova
				mensagem pelo WhatsApp.
			</p>
			<OrderSent className="mt-7" />
		</div>
	);
}
