export default function LogoTrigupAvatar() {
	return (
		<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
				fill="white"
			/>
			<path
				d="M25.0392 11.672C24.0713 10.704 22.8752 10.2102 21.4716 10.2102C20.068 10.2102 18.87 10.704 17.9039 11.672C16.9378 12.64 16.458 13.8154 16.458 15.2237C16.4575 15.5066 16.4782 15.789 16.52 16.0687V23.8557L18.9695 23.8622V19.6092C19.734 20.0333 20.5965 20.2489 21.4706 20.2345C22.8789 20.2345 24.0713 19.7594 25.0383 18.7914C26.0053 17.8235 26.5001 16.6274 26.5001 15.2237C26.5001 13.8201 26.0072 12.64 25.0392 11.672ZM23.3117 17.1015C22.8164 17.5767 22.1566 17.8421 21.4701 17.8421C20.7837 17.8421 20.1239 17.5767 19.6286 17.1015C19.1544 16.6076 18.9075 15.9814 18.9075 15.2237C18.9075 14.4661 19.1544 13.838 19.6286 13.346C20.1241 12.8712 20.7838 12.6061 21.4701 12.6061C22.1564 12.6061 22.8162 12.8712 23.3117 13.346C23.8056 13.8399 24.0525 14.4658 24.0525 15.2237C24.0525 15.9817 23.8056 16.6076 23.3117 17.1015Z"
				fill="#F55A00"
			/>
			<path
				d="M25.0392 11.672C24.0713 10.704 22.8752 10.2102 21.4716 10.2102C20.068 10.2102 18.87 10.704 17.9039 11.672C16.9378 12.64 16.458 13.8154 16.458 15.2237C16.4575 15.5066 16.4782 15.789 16.52 16.0687V23.8557L18.9695 23.8622V19.6092C19.734 20.0333 20.5965 20.2489 21.4706 20.2345C22.8789 20.2345 24.0713 19.7594 25.0383 18.7914C26.0053 17.8235 26.5001 16.6274 26.5001 15.2237C26.5001 13.8201 26.0072 12.64 25.0392 11.672ZM23.3117 17.1015C22.8164 17.5767 22.1566 17.8421 21.4701 17.8421C20.7837 17.8421 20.1239 17.5767 19.6286 17.1015C19.1544 16.6076 18.9075 15.9814 18.9075 15.2237C18.9075 14.4661 19.1544 13.838 19.6286 13.346C20.1241 12.8712 20.7838 12.6061 21.4701 12.6061C22.1564 12.6061 22.8162 12.8712 23.3117 13.346C23.8056 13.8399 24.0525 14.4658 24.0525 15.2237C24.0525 15.9817 23.8056 16.6076 23.3117 17.1015Z"
				fill="url(#paint0_linear_3514_24994)"
			/>
			<path
				d="M13.3407 10.1377C13.3698 10.8954 13.3407 14.8639 13.3407 15.048C13.3407 15.8072 13.0938 16.4331 12.6 16.9257C12.1049 17.4011 11.4452 17.6666 10.7588 17.6666C10.0725 17.6666 9.41276 17.4011 8.91773 16.9257C8.44267 16.4318 8.19575 15.8056 8.19575 15.048C8.19575 14.8602 8.21077 10.8954 8.23893 10.1377H5.77347C5.75751 10.8963 5.75 14.8686 5.75 15.048C5.75 16.4563 6.22413 17.6486 7.1921 18.6157C8.16007 19.5827 9.35712 20.0587 10.7598 20.0587C12.1625 20.0587 13.3604 19.5836 14.3275 18.6157C15.2945 17.6477 15.7893 16.4516 15.7893 15.048C15.7893 14.8686 15.7808 10.8963 15.7639 10.1377H13.3407Z"
				fill="#F55A00"
			/>
			<path
				d="M13.3407 10.1377C13.3698 10.8954 13.3407 14.8639 13.3407 15.048C13.3407 15.8072 13.0938 16.4331 12.6 16.9257C12.1049 17.4011 11.4452 17.6666 10.7588 17.6666C10.0725 17.6666 9.41276 17.4011 8.91773 16.9257C8.44267 16.4318 8.19575 15.8056 8.19575 15.048C8.19575 14.8602 8.21077 10.8954 8.23893 10.1377H5.77347C5.75751 10.8963 5.75 14.8686 5.75 15.048C5.75 16.4563 6.22413 17.6486 7.1921 18.6157C8.16007 19.5827 9.35712 20.0587 10.7598 20.0587C12.1625 20.0587 13.3604 19.5836 14.3275 18.6157C15.2945 17.6477 15.7893 16.4516 15.7893 15.048C15.7893 14.8686 15.7808 10.8963 15.7639 10.1377H13.3407Z"
				fill="url(#paint1_linear_3514_24994)"
			/>
			<path
				d="M15.7894 13.6033C15.7894 12.3302 15.7781 10.6111 15.7678 10.1379H13.3408C13.3568 10.552 13.3558 11.9274 13.3521 13.1188V13.193C13.3595 13.8242 13.4662 14.4503 13.6685 15.0482C14.6402 17.9465 17.7469 20.0933 21.4827 20.2313L21.4827 17.8414C18.4867 17.6949 16.087 15.881 15.7894 13.6033Z"
				fill="#F55A00"
			/>
			<path
				d="M15.7894 13.6033C15.7894 12.3302 15.7781 10.6111 15.7678 10.1379H13.3408C13.3568 10.552 13.3558 11.9274 13.3521 13.1188V13.193C13.3595 13.8242 13.4662 14.4503 13.6685 15.0482C14.6402 17.9465 17.7469 20.0933 21.4827 20.2313L21.4827 17.8414C18.4867 17.6949 16.087 15.881 15.7894 13.6033Z"
				fill="url(#paint2_linear_3514_24994)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_3514_24994"
					x1="21.4791"
					y1="18.6587"
					x2="24.6121"
					y2="13.5335"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_3514_24994"
					x1="15.7893"
					y1="19.0503"
					x2="9.3904"
					y2="16.1493"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_3514_24994"
					x1="21.4827"
					y1="19.2116"
					x2="15.9484"
					y2="17.2129"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
}
