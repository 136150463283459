import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";

import { persistor, store } from "./store";

import App from "./App";

import "./styles/fonts.scss";
import "./styles/global.scss";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<HelmetProvider>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</HelmetProvider>
		</Provider>
	</React.StrictMode>
);
