import OrderRefused from "assets/icons/order-refused";
import styles from "./styles.module.scss";

export default function BudgetRequestRefusedPage() {
	return (
		<div className={styles.successContainer}>
			<h1 className="display_lg mb-3 text-center">
				Orçamento
				<br /> Recusado
			</h1>
			<p className="text-lg font-normal text-center px-10 mb-6">
				Você recusou este orçamento.
				<br /> Quanto mais orçamentos respondidos, maiores serão suas chances de
				aumentar sua clientela e sua nota no TrigUP.
			</p>
			<OrderRefused className="mt-7" />
		</div>
	);
}
