import { baseConfig } from "config";
import { store } from "store";
import { setTokens } from "store/features/tokens/slice";
import { Requester } from "./requester";

export const getAccessToken = async () => {
	const { access } = store.getState().tokens;
	if (!access) {
		const response = await fetch(`${baseConfig.baseURL}/auth/token/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				email: baseConfig.api_user,
				password: baseConfig.api_password
			})
		});
		const parsedResponse = await response.json();
		setTokens(parsedResponse);
		return parsedResponse.access;
	}
	return access;
};

export const renewAccessToken = async () => {
	const { refresh } = store.getState().tokens;
	const response = await fetch(`${baseConfig.baseURL}/token/refresh`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			refresh
		})
	});
	const parsedResponse = await response.json();
	setTokens(parsedResponse);
	return parsedResponse.access;
};

export const getAuthToken = async (id: string | null = "") => {
	const { access_token } = store.getState().tokens;
	if (!access_token) {
		const response = await fetch(
			`${baseConfig.baseURL}/auth/magic-link/?token=${id}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json"
				}
			}
		);
		const parsedResponse = await response.json();
		setTokens(parsedResponse);
		return parsedResponse.access_token;
	}
	return access_token;
};

export const resetPassword = async (
	token: string,
	email: string,
	password: string,
	passwordConfirmation: string
) => {
	return Requester.put({
		url: `${baseConfig.baseURL}/auth/change-password/${token}/`,
		body: {
			email,
			new_password: password,
			new_password_confirm: passwordConfirmation
		},
		success: (result) => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};
