import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { baseConfig } from "config";

const sendToAnalytics = baseConfig.send_events_to_analytics === "true";

const firebaseConfig = {
	apiKey: baseConfig.firebase_api_key,
	authDomain: "trigup-webapp.firebaseapp.com",
	projectId: "trigup-webapp",
	storageBucket: "trigup-webapp.appspot.com",
	messagingSenderId: "1066900516687",
	appId: "1:1066900516687:web:1acfae4be17bf6cb401f3a",
	measurementId: "G-CR90SEHVPR"
};

let firebaseApp: FirebaseApp;
let analytics: Analytics;

export const initializeFirebase = () => {
	firebaseApp = initializeApp(firebaseConfig);
	analytics = getAnalytics(firebaseApp);
};

export const triggerAnalyticsEvent = async (key: string, params = {}) => {
	if (sendToAnalytics) {
		logEvent(analytics, key, params);
		return;
	}
	console.log(key, params);
};
