export default function OrderExpired(props: any) {
	return (
		<svg
			width="390"
			height="420"
			viewBox="0 0 390 420"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.27">
				<path
					opacity="0.02"
					d="M258.212 343.39C258.885 328.047 225.89 314.134 184.515 312.315C143.139 310.496 109.052 321.459 108.378 336.802C107.704 352.145 140.7 366.058 182.075 367.877C223.45 369.696 257.538 358.733 258.212 343.39Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.039"
					d="M256.847 343.334C257.526 328.187 224.897 314.419 183.968 312.582C143.04 310.745 109.31 321.535 108.631 336.682C107.952 351.829 140.581 365.597 181.51 367.434C222.439 369.271 256.169 358.481 256.847 343.334Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.059"
					d="M255.519 343.291C256.202 328.34 223.938 314.716 183.454 312.863C142.969 311.009 109.596 321.627 108.913 336.579C108.229 351.53 140.494 365.154 180.978 367.007C221.462 368.861 254.835 358.243 255.519 343.291Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.078"
					d="M254.146 343.28C254.834 328.525 222.938 315.047 182.902 313.176C142.867 311.305 109.854 321.749 109.165 336.504C108.477 351.259 140.373 364.737 180.409 366.608C220.444 368.479 253.457 358.034 254.146 343.28Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.098"
					d="M252.788 343.204C253.481 328.645 221.95 315.313 182.361 313.425C142.772 311.538 110.116 321.81 109.423 336.368C108.73 350.927 140.261 364.259 179.85 366.147C219.439 368.035 252.095 357.763 252.788 343.204Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.118"
					d="M251.427 343.2C252.124 328.838 220.96 315.651 181.818 313.746C142.676 311.842 110.38 321.941 109.682 336.303C108.984 350.665 140.149 363.852 179.291 365.757C218.432 367.662 250.729 357.563 251.427 343.2Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.137"
					d="M250.1 343.155C250.802 328.999 220.001 315.966 181.304 314.045C142.607 312.124 110.668 322.042 109.966 336.199C109.265 350.355 140.066 363.388 178.763 365.309C217.46 367.23 249.399 357.311 250.1 343.155Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.157"
					d="M248.71 343.137C249.416 329.178 218.982 316.291 180.734 314.353C142.486 312.416 110.907 322.162 110.201 336.121C109.494 350.08 139.928 362.967 178.176 364.905C216.425 366.842 248.004 357.097 248.71 343.137Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.176"
					d="M247.386 343.072C248.096 329.308 218.027 316.566 180.223 314.612C142.42 312.658 111.198 322.231 110.488 335.995C109.777 349.759 139.847 362.501 177.65 364.455C215.454 366.409 246.675 356.835 247.386 343.072Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.196"
					d="M246.018 343.054C246.733 329.487 217.03 316.892 179.675 314.921C142.32 312.951 111.458 322.352 110.744 335.919C110.029 349.486 139.732 362.082 177.087 364.052C214.442 366.023 245.303 356.622 246.018 343.054Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.216"
					d="M244.657 342.985C245.376 329.614 216.038 317.164 179.129 315.178C142.221 313.192 111.718 322.422 110.999 335.793C110.281 349.164 139.619 361.614 176.527 363.6C213.436 365.586 243.939 356.356 244.657 342.985Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.235"
					d="M243.307 342.982C244.029 329.818 215.056 317.523 178.594 315.521C142.132 313.519 111.989 322.567 111.267 335.732C110.545 348.896 139.518 361.191 175.979 363.193C212.441 365.195 242.585 356.147 243.307 342.982Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.255"
					d="M241.941 342.961C242.667 329.993 214.061 317.845 178.047 315.827C142.033 313.809 112.25 322.685 111.524 335.653C110.799 348.62 139.405 360.769 175.419 362.787C211.433 364.805 241.216 355.929 241.941 342.961Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.275"
					d="M240.603 342.89C241.332 330.118 213.089 318.115 177.52 316.082C141.951 314.048 112.525 322.753 111.796 335.526C111.067 348.298 139.31 360.301 174.879 362.334C210.448 364.368 239.874 355.662 240.603 342.89Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.294"
					d="M239.241 342.868C239.974 330.293 212.097 318.437 176.976 316.388C141.855 314.338 112.79 322.872 112.057 335.448C111.324 348.023 139.201 359.879 174.322 361.928C209.443 363.978 238.509 355.444 239.241 342.868Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.314"
					d="M237.89 342.856C238.625 330.487 211.104 318.786 176.419 316.721C141.734 314.656 113.02 323.008 112.285 335.377C111.549 347.746 139.071 359.447 173.756 361.512C208.441 363.577 237.155 355.224 237.89 342.856Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.333"
					d="M236.561 342.789C237.299 330.616 210.142 319.061 175.903 316.98C141.663 314.9 113.308 323.081 112.569 335.254C111.831 347.427 138.988 358.982 173.228 361.063C207.467 363.143 235.822 354.962 236.561 342.789Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.353"
					d="M235.202 342.742C235.944 330.766 209.152 319.358 175.361 317.263C141.569 315.167 113.575 323.178 112.833 335.154C112.091 347.131 138.883 358.538 172.675 360.634C206.466 362.729 234.461 354.719 235.202 342.742Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.372"
					d="M233.826 342.715C234.57 330.945 208.143 319.694 174.799 317.583C141.456 315.473 113.822 323.303 113.078 335.073C112.334 346.842 138.762 358.094 172.105 360.204C205.449 362.314 233.083 354.484 233.826 342.715Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.392"
					d="M232.467 342.678C233.213 331.105 207.15 320.001 174.253 317.877C141.357 315.753 114.084 323.413 113.338 334.986C112.591 346.559 138.654 357.663 171.551 359.787C204.447 361.911 231.72 354.252 232.467 342.678Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.412"
					d="M231.112 342.651C231.861 331.274 206.164 320.318 173.716 318.179C141.267 316.04 114.356 323.529 113.607 334.905C112.858 346.281 138.555 357.238 171.003 359.377C203.452 361.516 230.363 354.027 231.112 342.651Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.431"
					d="M229.739 342.596C230.49 331.416 205.158 320.608 173.157 318.455C141.156 316.302 114.606 323.619 113.855 334.8C113.104 345.98 138.436 356.788 170.437 358.941C202.437 361.094 228.988 353.776 229.739 342.596Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.451"
					d="M228.432 342.537C229.185 331.563 204.213 320.91 172.657 318.743C141.101 316.576 114.909 323.715 114.157 334.689C113.404 345.663 138.375 356.316 169.932 358.483C201.488 360.651 227.679 353.511 228.432 342.537Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.471"
					d="M227.08 342.499C227.834 331.721 203.227 321.216 172.118 319.036C141.009 316.855 115.179 323.825 114.425 334.602C113.67 345.38 138.277 355.885 169.386 358.066C200.495 360.246 226.325 353.277 227.08 342.499Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.49"
					d="M225.732 342.462C226.488 331.881 202.236 321.523 171.564 319.329C140.891 317.134 115.413 323.933 114.657 334.514C113.901 345.096 138.153 355.453 168.825 357.648C199.498 359.843 224.975 353.044 225.732 342.462Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.51"
					d="M224.344 342.454C225.101 332.081 201.215 321.881 170.993 319.673C140.771 317.465 115.657 324.084 114.9 334.458C114.143 344.831 138.03 355.031 168.252 357.239C198.474 359.447 223.587 352.828 224.344 342.454Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.529"
					d="M222.998 342.39C223.757 332.213 200.234 322.162 170.459 319.941C140.684 317.72 115.932 324.17 115.174 334.348C114.416 344.525 137.939 354.576 167.714 356.797C197.488 359.018 222.24 352.568 222.998 342.39Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.549"
					d="M221.637 342.348C222.397 332.367 199.237 322.465 169.909 320.231C140.581 317.998 116.191 324.278 115.432 334.26C114.673 344.241 137.832 354.143 167.16 356.377C196.488 358.61 220.878 352.33 221.637 342.348Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.569"
					d="M220.294 342.301C221.053 332.527 198.256 322.782 169.375 320.536C140.495 318.29 116.467 324.393 115.708 334.167C114.948 343.942 137.746 353.686 166.626 355.932C195.507 358.178 219.535 352.075 220.294 342.301Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.588"
					d="M218.977 342.257C219.737 332.679 197.294 323.083 168.849 320.824C140.404 318.565 116.729 324.499 115.969 334.077C115.21 343.656 137.653 353.252 166.098 355.51C194.543 357.769 218.218 351.835 218.977 342.257Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.608"
					d="M217.581 342.216C218.34 332.845 196.261 323.408 168.266 321.137C140.27 318.866 116.96 324.622 116.201 333.993C115.442 343.363 137.521 352.8 165.517 355.071C193.512 357.342 216.822 351.586 217.581 342.216Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.627"
					d="M216.249 342.164C217.008 332.989 195.29 323.702 167.741 321.42C140.192 319.138 117.244 324.726 116.486 333.9C115.727 343.075 137.444 352.363 164.993 354.645C192.542 356.927 215.49 351.339 216.249 342.164Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.647"
					d="M214.877 342.14C215.635 333.162 194.282 324.025 167.182 321.732C140.083 319.439 117.499 324.858 116.74 333.836C115.982 342.813 137.335 351.951 164.435 354.244C191.534 356.537 214.118 351.118 214.877 342.14Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.667"
					d="M213.528 342.08C214.285 333.309 193.292 324.331 166.639 322.027C139.986 319.723 117.765 324.966 117.008 333.737C116.251 342.508 137.244 351.487 163.897 353.79C190.55 356.094 212.771 350.851 213.528 342.08Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.686"
					d="M212.182 342.03C212.938 333.456 192.3 324.628 166.085 322.313C139.87 319.998 118.005 325.073 117.249 333.647C116.493 342.222 137.131 351.05 163.346 353.365C189.561 355.68 211.426 350.605 212.182 342.03Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.706"
					d="M210.86 341.969C211.614 333.601 191.336 324.932 165.567 322.607C139.798 320.282 118.297 325.181 117.543 333.549C116.789 341.917 137.068 350.586 162.836 352.911C188.605 355.236 210.106 350.337 210.86 341.969Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.726"
					d="M209.481 341.93C210.233 333.758 190.317 325.241 164.997 322.907C139.676 320.573 118.54 325.305 117.788 333.476C117.036 341.648 136.952 350.165 162.272 352.499C187.592 354.833 208.729 350.102 209.481 341.93Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.745"
					d="M208.142 341.89C208.891 333.925 189.327 325.568 164.445 323.224C139.562 320.88 118.784 325.435 118.034 333.4C117.285 341.364 136.848 349.721 161.731 352.065C186.613 354.41 207.392 349.854 208.142 341.89Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.765"
					d="M206.79 341.848C207.537 334.08 188.335 325.875 163.901 323.522C139.466 321.168 119.053 325.557 118.306 333.325C117.558 341.093 136.761 349.299 161.195 351.652C185.629 354.005 206.043 349.616 206.79 341.848Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.784"
					d="M205.422 341.777C206.165 334.216 187.323 326.173 163.337 323.811C139.351 321.45 119.304 325.665 118.56 333.226C117.817 340.786 136.659 348.83 160.645 351.192C184.631 353.553 204.678 349.338 205.422 341.777Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.804"
					d="M204.102 341.738C204.842 334.373 186.353 326.481 162.804 324.11C139.256 321.739 119.565 325.788 118.825 333.152C118.085 340.517 136.574 348.409 160.123 350.78C183.671 353.15 203.361 349.102 204.102 341.738Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.824"
					d="M202.721 341.651C203.456 334.494 185.326 326.765 162.226 324.388C139.125 322.011 119.803 325.886 119.067 333.044C118.332 340.201 136.462 347.93 159.562 350.307C182.662 352.684 201.985 348.809 202.721 341.651Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.843"
					d="M201.38 341.643C202.112 334.682 184.333 327.105 161.671 324.72C139.008 322.335 120.043 326.044 119.312 333.005C118.58 339.967 136.359 347.543 159.021 349.929C181.684 352.314 200.649 348.604 201.38 341.643Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.863"
					d="M200.05 341.563C200.776 334.809 183.357 327.394 161.143 325.002C138.929 322.61 120.332 326.146 119.606 332.9C118.879 339.655 136.299 347.069 158.513 349.461C180.727 351.853 199.324 348.317 200.05 341.563Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.882"
					d="M198.67 341.526C199.39 334.979 182.329 327.728 160.564 325.331C138.798 322.934 120.57 326.298 119.85 332.845C119.13 339.392 136.19 346.643 157.956 349.04C179.721 351.437 197.95 348.073 198.67 341.526Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.902"
					d="M197.331 341.485C198.045 335.134 181.335 328.037 160.008 325.634C138.68 323.231 120.811 326.431 120.096 332.782C119.382 339.132 136.092 346.229 157.419 348.632C178.747 351.036 196.616 347.836 197.331 341.485Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.922"
					d="M195.967 341.419C196.674 335.275 180.321 328.344 159.442 325.938C138.563 323.532 121.064 326.563 120.357 332.706C119.65 338.85 136.003 345.781 156.882 348.187C177.761 350.593 195.26 347.563 195.967 341.419Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.941"
					d="M194.626 341.355C195.326 335.418 179.322 328.65 158.881 326.238C138.44 323.826 121.302 326.683 120.602 332.62C119.903 338.557 135.906 345.325 156.347 347.737C176.788 350.149 193.926 347.291 194.626 341.355Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.961"
					d="M193.297 341.286C193.989 335.546 178.335 328.934 158.331 326.518C138.328 324.103 121.551 326.798 120.859 332.539C120.167 338.279 135.821 344.891 155.824 347.307C175.828 349.722 192.604 347.027 193.297 341.286Z"
					fill="#0F0A26"
				/>
				<path
					opacity="0.98"
					d="M191.948 341.201C192.631 335.667 177.333 329.221 157.779 326.804C138.225 324.387 121.819 326.914 121.136 332.447C120.453 337.981 135.751 344.427 155.305 346.844C174.859 349.261 191.265 346.734 191.948 341.201Z"
					fill="#0F0A26"
				/>
				<path
					d="M190.596 341.174C191.269 335.847 176.318 329.567 157.202 327.147C138.086 324.727 122.044 327.084 121.37 332.411C120.697 337.737 135.648 344.017 154.764 346.437C173.88 348.857 189.922 346.501 190.596 341.174Z"
					fill="#0F0A26"
				/>
			</g>
			<path
				d="M320.257 108.924L185.108 347.485C181.332 354.136 172.836 356.4 166.267 352.483L76.3708 298.954C70.2151 295.293 64.9641 283.308 68.5041 277.07L192.03 68.678C195.669 62.2426 203.24 59.1135 210.36 61.0815L314.927 84.1465C323.384 86.5081 324.584 101.268 320.257 108.924Z"
				fill="url(#paint0_linear_742_67)"
			/>
			<path
				d="M317.622 99.9298L182.472 338.491C178.696 345.143 170.2 347.406 163.632 343.489L73.7352 289.96C67.5796 286.299 65.4752 278.388 69.0152 272.149L189.375 59.7039C193.014 53.2685 200.585 50.1394 207.705 52.1074L309.48 80.2695C317.936 82.5917 321.948 92.2743 317.622 99.9298Z"
				fill="url(#paint1_linear_742_67)"
			/>
			<path
				d="M313.492 90.1094C312.508 87.7478 310.542 85.9963 308.064 85.3075L270.5 74.9164C268.848 74.4638 267.098 75.0936 266.115 76.5105L265.21 77.8094C264.01 79.5412 261.867 80.3088 259.841 79.7577L241.59 74.7196C239.997 74.2867 239.27 72.4171 240.155 71.0198L241.059 69.5832C241.807 68.4024 241.197 66.828 239.84 66.454L206.269 57.1651C205.325 56.9092 204.342 56.7715 203.378 56.7715C199.464 56.7715 195.846 58.8772 193.918 62.2819L73.5583 274.727C71.4539 278.447 72.7322 283.249 76.3902 285.433L166.287 338.943C167.585 339.711 169.06 340.124 170.554 340.124C173.583 340.124 176.376 338.491 177.87 335.873L313.02 97.3123C314.318 95.1081 314.475 92.471 313.492 90.1094Z"
				fill="url(#paint2_linear_742_67)"
			/>
			<path
				opacity="0.54"
				d="M318.979 90.2668C319.451 93.1795 319.018 96.2693 317.405 99.0835L182.256 337.644C178.48 344.296 169.984 346.559 163.415 342.643L73.5189 289.114C70.5492 287.342 68.5629 284.587 67.6582 281.517C68.4252 284.922 70.5099 288.011 73.7352 289.94L163.632 343.47C170.2 347.386 178.696 345.123 182.472 338.471L317.622 99.91C319.372 96.8203 319.706 93.4156 318.979 90.2668Z"
				fill="url(#paint3_linear_742_67)"
			/>
			<path
				d="M270.835 106.385C248.592 99.6149 237.303 95.9544 215.04 88.6728C214.568 88.5154 214.45 87.9447 214.745 87.4133C215.04 86.8623 215.669 86.5474 216.122 86.7048C238.384 93.9864 249.693 97.6272 271.936 104.417C272.408 104.555 272.526 105.106 272.231 105.657C271.936 106.208 271.307 106.542 270.835 106.385ZM278.524 114.769C247.982 105.598 232.229 100.441 201.647 90.3456C201.195 90.1882 201.057 89.6175 201.352 89.0861C201.647 88.5351 202.276 88.2202 202.729 88.3776C233.33 98.4538 249.083 103.61 279.625 112.761C280.078 112.899 280.215 113.47 279.901 114.021C279.625 114.572 278.996 114.906 278.524 114.769ZM275.613 120.043C245.091 110.813 229.377 105.637 198.815 95.5215C198.363 95.364 198.225 94.7933 198.52 94.2423C198.815 93.6912 199.444 93.3763 199.897 93.5141C230.478 103.63 246.212 108.786 276.735 117.996C277.187 118.134 277.324 118.705 277.01 119.275C276.715 119.846 276.085 120.181 275.613 120.043ZM169.453 288.641C149.531 277.758 139.559 272.307 119.657 261.404C119.244 261.168 119.145 260.538 119.46 259.987C119.755 259.436 120.345 259.18 120.758 259.397C140.68 270.3 150.651 275.751 170.593 286.595C171.006 286.831 171.085 287.461 170.79 288.031C170.456 288.602 169.866 288.877 169.453 288.641ZM176.021 299.072C148.547 284.017 134.8 276.459 107.326 261.365C106.913 261.129 106.815 260.499 107.11 259.968C107.405 259.417 107.995 259.161 108.408 259.397C135.882 274.472 149.649 282.029 177.123 297.064C177.536 297.301 177.634 297.93 177.32 298.481C177.025 299.032 176.454 299.288 176.021 299.072ZM173.19 304.228C145.735 289.133 131.988 281.596 104.533 266.501C104.12 266.265 104.022 265.635 104.317 265.104C104.612 264.553 105.202 264.297 105.615 264.533C133.089 279.628 146.817 287.165 174.291 302.26C174.704 302.496 174.802 303.126 174.507 303.657C174.192 304.189 173.603 304.444 173.19 304.228Z"
				fill="#B0B5C1"
			/>
			<path
				d="M213.836 226.284L212.418 163.074C212.317 160.795 211.557 158.718 210.291 157.047C209.025 155.376 207.252 154.211 205.175 153.552C203.099 152.894 200.769 152.843 198.388 153.299C196.008 153.806 193.678 154.819 191.551 156.338L139.534 194.831C137.761 196.148 136.191 197.87 135.077 199.846C133.963 201.821 133.253 203.898 133.051 206.025C132.848 208.405 133.253 210.634 134.216 212.457C135.178 214.331 136.698 215.8 138.572 216.661L191.804 241.327C194.032 242.391 196.666 242.796 199.351 242.492C202.035 242.188 204.719 241.125 207.1 239.453C209.227 237.934 211 235.857 212.165 233.527C213.33 231.248 213.938 228.716 213.836 226.284ZM202.44 232.16C201.478 232.818 200.414 233.274 199.351 233.375C198.287 233.477 197.223 233.325 196.362 232.869L142.877 209.013C142.117 208.709 141.459 208.152 141.053 207.443C140.648 206.734 140.446 205.822 140.496 204.86C140.547 204.151 140.75 203.442 141.155 202.783C141.509 202.125 142.016 201.517 142.624 201.061L195.4 162.315C196.261 161.757 197.223 161.403 198.186 161.251C199.148 161.099 200.06 161.15 200.87 161.403C201.68 161.656 202.39 162.163 202.947 162.77C203.453 163.429 203.808 164.189 203.909 165.05L204.77 227.753C204.77 228.564 204.567 229.374 204.162 230.134C203.757 230.995 203.149 231.653 202.44 232.16Z"
				fill="#F55A00"
			/>
			<path
				d="M213.836 226.284L212.418 163.074C212.317 160.795 211.557 158.718 210.291 157.047C209.025 155.376 207.252 154.211 205.175 153.552C203.099 152.894 200.769 152.843 198.388 153.299C196.008 153.806 193.678 154.819 191.551 156.338L139.534 194.831C137.761 196.148 136.191 197.87 135.077 199.846C133.963 201.821 133.253 203.898 133.051 206.025C132.848 208.405 133.253 210.634 134.216 212.457C135.178 214.331 136.698 215.8 138.572 216.661L191.804 241.327C194.032 242.391 196.666 242.796 199.351 242.492C202.035 242.188 204.719 241.125 207.1 239.453C209.227 237.934 211 235.857 212.165 233.527C213.33 231.248 213.938 228.716 213.836 226.284ZM202.44 232.16C201.478 232.818 200.414 233.274 199.351 233.375C198.287 233.477 197.223 233.325 196.362 232.869L142.877 209.013C142.117 208.709 141.459 208.152 141.053 207.443C140.648 206.734 140.446 205.822 140.496 204.86C140.547 204.151 140.75 203.442 141.155 202.783C141.509 202.125 142.016 201.517 142.624 201.061L195.4 162.315C196.261 161.757 197.223 161.403 198.186 161.251C199.148 161.099 200.06 161.15 200.87 161.403C201.68 161.656 202.39 162.163 202.947 162.77C203.453 163.429 203.808 164.189 203.909 165.05L204.77 227.753C204.77 228.564 204.567 229.374 204.162 230.134C203.757 230.995 203.149 231.653 202.44 232.16Z"
				fill="url(#paint4_linear_742_67)"
			/>
			<path
				d="M171.848 214.129C174.228 215.142 177.318 213.825 178.787 211.191C180.256 208.557 179.445 205.62 177.065 204.657C174.684 203.695 171.645 205.012 170.227 207.595C168.758 210.178 169.518 213.116 171.848 214.129Z"
				fill="#F55A00"
			/>
			<path
				d="M171.848 214.129C174.228 215.142 177.318 213.825 178.787 211.191C180.256 208.557 179.445 205.62 177.065 204.657C174.684 203.695 171.645 205.012 170.227 207.595C168.758 210.178 169.518 213.116 171.848 214.129Z"
				fill="url(#paint5_linear_742_67)"
			/>
			<path
				d="M195.096 171.989C193.931 171.584 192.563 171.685 191.247 172.293C189.98 172.85 188.866 173.863 188.208 175.078L177.976 193.565C177.318 194.781 177.065 196.148 177.419 197.364C177.723 198.58 178.534 199.491 179.699 199.947C180.863 200.403 182.231 200.352 183.497 199.795C184.814 199.238 185.928 198.225 186.637 196.959L196.97 178.168C197.628 176.952 197.831 175.585 197.476 174.42C197.071 173.255 196.261 172.394 195.096 171.989Z"
				fill="#F55A00"
			/>
			<path
				d="M195.096 171.989C193.931 171.584 192.563 171.685 191.247 172.293C189.98 172.85 188.866 173.863 188.208 175.078L177.976 193.565C177.318 194.781 177.065 196.148 177.419 197.364C177.723 198.58 178.534 199.491 179.699 199.947C180.863 200.403 182.231 200.352 183.497 199.795C184.814 199.238 185.928 198.225 186.637 196.959L196.97 178.168C197.628 176.952 197.831 175.585 197.476 174.42C197.071 173.255 196.261 172.394 195.096 171.989Z"
				fill="url(#paint6_linear_742_67)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_742_67"
					x1="245.373"
					y1="131.817"
					x2="107.544"
					y2="316.529"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#773FAF" />
					<stop offset="1" stopColor="#DEBDFF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_742_67"
					x1="67.2897"
					y1="198.455"
					x2="319.379"
					y2="198.455"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#773FAF" />
					<stop offset="1" stopColor="#DEBDFF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_742_67"
					x1="72.5564"
					y1="198.455"
					x2="314.126"
					y2="198.455"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F9FCFF" />
					<stop offset="1" stopColor="#E7EDF7" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_742_67"
					x1="221.724"
					y1="334.788"
					x2="202.873"
					y2="236.114"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="#8F92C7" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_742_67"
					x1="209.5"
					y1="156.59"
					x2="181.467"
					y2="234.01"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_742_67"
					x1="178.982"
					y1="204.748"
					x2="176.041"
					y2="213.679"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_742_67"
					x1="196.553"
					y1="172.888"
					x2="186.02"
					y2="195.942"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF9900" />
					<stop offset="1" stopColor="#FF9900" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
}
