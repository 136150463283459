const StringHelper = {
	getFirstName(fullName: string) {
		return fullName.split(" ")[0];
	},
	sanitizeString(value = "") {
		return value.replace(/[^a-zA-Z0-9]/g, "");
	},
	isValidPhone(phoneString: string): boolean {
		return !!/\(\d{2}\) \d{5}-\d{4}/g.exec(phoneString);
	}
};

export default StringHelper;
