import { ActionButtonProps } from "./types";

export default function ActionButton({
	icon,
	text,
	onClick
}: ActionButtonProps) {
	return (
		<button
			type="button"
			className="button w-fit flex text-primary-700 px-4 py-2 border-none gap-2 items-center"
			onClick={onClick}
		>
			{icon} {text}
		</button>
	);
}
